import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@natlex/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { IrisRelativeDatetimeInputComponent } from './component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { IrisNumberFieldModule } from '@iris/common/modules/fields/number-field/number-field.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    TranslateModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonToggleModule,
    IrisNumberFieldModule,
  ],
  declarations: [IrisRelativeDatetimeInputComponent],
  exports: [IrisRelativeDatetimeInputComponent],
})
export class IrisRelativeDatetimeInputComponentModule { }
