<button type="button"
        class="btn announcement-btn"
        data-test="new-functionality-exist-btn"
        [ngxTippy]="popoverTemplate"
        (click)="goToReleaseNotes($event)">
    <span>{{ 'label.New' | translate }}</span>
</button>

<ng-template #popoverTemplate>
    {{ config.customText ? (config.customText | irisTranslate: config.translations)?.customText ?? config.customText : 'label.ForMoreInfoClickBtn' | translate }}
</ng-template>
