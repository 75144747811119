import { computed, inject, Injectable, Signal } from '@angular/core';
import { EmailWellKnownFolderName, IrisEmailNavigationI } from '../../models/IrisEmailNavigation';
import { IrisEmailsNavigationStore } from './emails-navigation.signal-store';
import { toObservable } from '@angular/core/rxjs-interop';
import { IrisEmailsMessagesStore } from '../messages/emails-messages.signal-store';

@Injectable()
export class IrisEmailsNavigationSandbox {
  private readonly emailsNavigationStore = inject(IrisEmailsNavigationStore);
  private readonly emailsMessagesStore = inject(IrisEmailsMessagesStore);

  readonly navigationFolders = this.emailsNavigationStore.entities;

  readonly inboxNavigationItemId = this.emailsNavigationStore.getFolderIdByShortcut(EmailWellKnownFolderName.Inbox);
  readonly inboxNavigationItemId$ = toObservable(this.inboxNavigationItemId);
  readonly inboxNavigationItem = this.emailsNavigationStore.getFolderByShortcut(EmailWellKnownFolderName.Inbox);
  readonly draftNavigationItemId = this.emailsNavigationStore.getFolderIdByShortcut(EmailWellKnownFolderName.Drafts);
  readonly sentNavigationItemId = this.emailsNavigationStore.getFolderIdByShortcut(EmailWellKnownFolderName.SentItems);
  readonly deletedNavigationItemId = this.emailsNavigationStore.getFolderIdByShortcut(EmailWellKnownFolderName.DeletedItems);

  readonly selectedFolderId = this.emailsNavigationStore.selectedFolderId;
  readonly isDeletedFolderSelected = computed(() => {
    const selectedFolderId = this.selectedFolderId();
    const deletedNavigationItemId = this.deletedNavigationItemId();
    return selectedFolderId === deletedNavigationItemId;
  });

  readonly sortedNavigationFolders = this.emailsNavigationStore.sortedVisibleFlatNavigationTree;
  readonly childrenFolders = (parentFolderId: string): Signal<IrisEmailNavigationI[]> => this.emailsNavigationStore.getChildren(parentFolderId);

  getCustomFolders(): void {
    this.emailsNavigationStore.fetchCustomFolders$();
  }
  
  getChildrenFolders(parentFolderId: string): void {
    this.emailsNavigationStore.fetchChildrenFolders$(parentFolderId);
  }

  getFolderIdByShortcut(folderShortcut: EmailWellKnownFolderName): Signal<string> {
    return this.emailsNavigationStore.getFolderIdByShortcut(folderShortcut);
  }

  setSelectedFolderId(folderId: string): void {
    this.emailsNavigationStore.setSelectedFolderId(folderId);
    this.emailsMessagesStore.setOpenedDraftMessage(null);
  }

  fetchCustomFolder(folderId: string | EmailWellKnownFolderName): void {
    this.emailsNavigationStore.fetchCustomFolder$(folderId);
  }

  fetchInboxFolder(): void {
    this.fetchCustomFolder(EmailWellKnownFolderName.Inbox);
  }

  collapseFolder(folderId: string): void {
    this.emailsNavigationStore.collapseFolder(folderId);
  }
}
