import { createAction, props } from '@ngrx/store';
import { EmailMessageChangeEventI, IrisEmailMessageI, IrisEmailMessageResponseI } from '../../models/IrisEmail';

const prefixed = (type: string): string => `[EmailsMessages] ${type}`;

export const MarkMessageAsReadSuccess = createAction(
  prefixed('Mark Message As Read Success'),
);

export const MarkMessageAsUnreadSuccess = createAction(
  prefixed('Mark Message As Unread Success'),
);

export const SetMessageReadMarkBulkSuccess = createAction(
  prefixed('Set Messages Read Mark Success'),
);

export const SetMessageReadMarkBulkError = createAction(
  prefixed('Set Messages Read Mark Error'),
);

export const RemoveEmailMessageSuccess = createAction(
  prefixed('Remove Email Message Success'),
  props<{ message: IrisEmailMessageI }>(),
);

export const RemoveSelectedEmailMessagesSuccess = createAction(
  prefixed('Remove Selected Email Messages Success'),
  props<{
    selectedMessagesIds: string[];
    unselectedMessagesIds: string[];
    selectedFolderId: string;
    selectedMessagesCount: number;
  }>(),
);

export const MoveMessagesToFolderSuccess = createAction(
  prefixed('Move MessagesTo Folder Success'),
  props<{ targetFolderId: string; sourceFolderId: string }>(),
);

export const RemoveMessageAttachmentError = createAction(
  prefixed('Remove Message Attachment Error'),
);

export const ForwardOrReplyMessageSuccess = createAction(
  prefixed('Forward Message Success'),
  props<{
    message: Partial<IrisEmailMessageResponseI>;
    silent: boolean;
    forwardedMessage: IrisEmailMessageI;
  }>(),
);

export const FetchMessagesRangeStart = createAction(
  prefixed('Fetch Messages Range Start'),
  props<{ offset: number; limit: number }>(),
);

export const EmailsMessageChangeEvent = createAction(
  prefixed('Emails User Change Event'),
  props<{ event: EmailMessageChangeEventI }>(),
);
