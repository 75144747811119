import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrl, IrisApiClientCore } from '@iris/api-query';
import { Observable } from 'rxjs';
import { AnnouncementNewFunctionalityConfig } from '@iris/modules/administration/administration-list/announcement-page/models/announcement-models';

@Injectable({ providedIn: 'root' })
@ApiUrl(`{iris@formsUrlPart}/new-functionality-identification/config`)
export class IrisAnnouncementsService extends IrisApiClientCore {
  constructor(private readonly httpClient: HttpClient) {
    super();
  }

  getAnnouncementConfigs(onlyActive = false): Observable<AnnouncementNewFunctionalityConfig[]> {
    return this.httpClient.get<AnnouncementNewFunctionalityConfig[]>(this.url(), { params: { 'only-active': onlyActive } });
  }

  createConfig(config: AnnouncementNewFunctionalityConfig): Observable<AnnouncementNewFunctionalityConfig> {
    return this.httpClient.post<AnnouncementNewFunctionalityConfig>(this.url(), config);
  }

  @ApiUrl('~/{configId}')
  saveConfig(config: AnnouncementNewFunctionalityConfig, configId: number): Observable<AnnouncementNewFunctionalityConfig> {
    return this.httpClient.post<AnnouncementNewFunctionalityConfig>(this.url({ configId }), config);
  }

  @ApiUrl('~/by-template/{documentTemplateId}')
  getConfigByTemplateId(documentTemplateId: number): Observable<AnnouncementNewFunctionalityConfig> {
    return this.httpClient.get<AnnouncementNewFunctionalityConfig>(this.url({ documentTemplateId }));
  }

  @ApiUrl('~/by-template/{documentTemplateId}')
  removeConfigByTemplateId(documentTemplateId: number): Observable<AnnouncementNewFunctionalityConfig> {
    return this.httpClient.delete<AnnouncementNewFunctionalityConfig>(this.url({ documentTemplateId }));
  }
}
