/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as fromGlobal from './global.reducer';
import * as fromProjects from './projects.reducer';
import * as fromResourceRoles from './resource-roles.reducer';
import { ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { environment } from '@environments/environment';
import { IrisModuleAliasType } from '@iris/common/services/settings/global-settings.service';
import { routerReducer } from '@ngrx/router-store';

export interface State {
  global: fromGlobal.State;
  globalProjects: fromProjects.State;
  resourceRoles: fromResourceRoles.State;
  projectEnabledRoles: fromResourceRoles.State;
  projectRoles: fromResourceRoles.State;
  router: State;
}

export const reducers: ActionReducerMap<State> = {
  global: fromGlobal.reducer,
  globalProjects: fromProjects.reducer,
  resourceRoles: fromResourceRoles.reducer,
  projectEnabledRoles: fromResourceRoles.reducer,
  projectRoles: fromResourceRoles.reducer,
  router: routerReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

export const getGlobalState = createFeatureSelector<fromGlobal.State>('global');
export const getProjectsState = createFeatureSelector<fromProjects.State>('globalProjects');
export const getEnabledResourceRolesState = createFeatureSelector<fromResourceRoles.State>('resourceRoles');
export const getEnabledProjectResourceRolesState = createFeatureSelector<fromResourceRoles.State>('projectEnabledRoles');
export const getAllProjectResourceRolesState = createFeatureSelector<fromResourceRoles.State>('projectRoles');

export const getGlobalCompanies = createSelector(
  getGlobalState,
  fromGlobal.getCompanies,
);

export const getGlobalLanguages = createSelector(
  getGlobalState,
  fromGlobal.getLanguages,
);

export const getGlobalUsers = createSelector(
  getGlobalState,
  fromGlobal.getUsers,
);

export const getGlobalUsersMap = createSelector(
  getGlobalState,
  fromGlobal.getUsersById,
);

export const getGlobalUsersEmails = createSelector(
  getGlobalUsersMap,
  (usersById) => Object.values(usersById).map(user => user.email),
);

export const getGlobalUserByEmail = (email: string) => createSelector(
  getGlobalUsersMap,
  (users) => Object.values(users).find(user => user.email === email),
);

export const getGlobalUsersInfoMap = createSelector(
  getGlobalState,
  fromGlobal.getUsersInfoById,
);

export const getGlobalUsersInfoIds = createSelector(
  getGlobalUsersInfoMap,
  (usersById) => Object.keys(usersById).map(key => parseInt(key)),
);

export const getGlobalUserInfoById = (userId: number) => createSelector(
  getGlobalUsersInfoMap,
  (ids) => ids[userId],
);

export const getGlobalCountries = createSelector(
  getGlobalState,
  fromGlobal.getGlobalCountries,
);

export const getGlobalProjects = createSelector(
  getGlobalState,
  fromGlobal.getProjects,
);

export const getProjectTypes = createSelector(
  getProjectsState,
  fromProjects.getProjectTypes,
);

export const getCurrentProject = createSelector(
  getProjectsState,
  fromProjects.getCurrentProject,
);

export const getCurrentProjectId = createSelector(
  getProjectsState,
  fromProjects.getCurrentProjectId,
);

export const getRecentProjectsIds = createSelector(
  getProjectsState,
  fromProjects.getRecentProjectsIds,
);

export const getRecentProjects = createSelector(
  getProjectsState,
  fromProjects.getRecentProjects,
);

export const getLastProjectIdFormRecent = createSelector(
  getProjectsState,
  fromProjects.getLastProjectIdFormRecent,
);

export const getDepartmentPaths = createSelector(
  getProjectsState,
  fromProjects.getDepartmentPaths,
);

export const getDepartmentsIds = createSelector(
  getDepartmentPaths,
  (paths) => Object.keys(paths),
);

export const getDepartmentPathById = (departmentId: string) => createSelector(
  getDepartmentPaths,
  (ids) => ids[departmentId],
);

export const getGlobalUnits = createSelector( getGlobalState, fromGlobal.getUnits );

export const getGlobalUserGroups = (companyId?: number) => createSelector(
  getGlobalState,
  state => fromGlobal.getUserGroups(state, companyId),
);

export const getGlobalSettingsMap = createSelector(
  getGlobalState,
  fromGlobal.getGlobalSettings,
);

export const getGlobalSettingsByAlias = (alias: IrisModuleAliasType) => createSelector(
  getGlobalSettingsMap,
  (settingsMap) => settingsMap[alias],
);

export const getEnabledResourceRoles = (resourceType: string) => createSelector(
  getEnabledResourceRolesState,
  state => fromResourceRoles.getEnabledResourceRoles(state, resourceType),
);

export const getEnabledProjectResourceRoles = (projectId: number) => createSelector(
  getEnabledProjectResourceRolesState,
  state => fromResourceRoles.getEnabledProjectResourceRoles(state, projectId),
);

export const getAllProjectResourceRoles = (projectId: number) => createSelector(
  getAllProjectResourceRolesState,
  state => fromResourceRoles.getAllProjectResourceRoles(state, projectId),
);
