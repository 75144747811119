import { Pipe, PipeTransform } from '@angular/core';
import { IrisLocaleService } from '../../../locale/services/locale.service';
import { transformTranslation } from './translate.utils';
import { IrisFieldsTranslationSet } from '@iris/common/models';

@Pipe({
  name: 'irisTranslation',
  standalone: true,
})
export class IrisTranslationPipe implements PipeTransform {

  constructor(private readonly localeService: IrisLocaleService) {}

  transform(defaultValue: string, translations: IrisFieldsTranslationSet, translationKey: string, currLocale?: string): string {
    return transformTranslation(translationKey, translations, defaultValue, currLocale, this.localeService);
  }
}
