import { computed, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { EmailCategory } from '@iris/common/modules/emails/models/email-categories';
import { IrisEmailMessageI } from '@iris/common/modules/emails/models/IrisEmail';
import { IrisEmailsNavigationSandbox } from '@iris/common/modules/emails/sandbox/navigation/emails-navigation.sandbox';
import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { take, Observable, switchMap, filter } from 'rxjs';
import {
  DRAFT_MESSAGE_PATH_NAME,
  FORWARDED_MESSAGE_QUERY_NAME,
  IrisEmailsTabType,
  NEW_MESSAGE_PATH_NAME,
  REPLIED_MESSAGE_QUERY_NAME,
  REPLY_ALL_QUERY_NAME,
  ROOT_EMAILS_PATH,
} from '../utils/emails-routing.utils';

@Injectable({ providedIn: 'root' })
export class IrisEmailsRoutingService {
  readonly baseEmailsPath = computed(() => {
    const projectId = this.globalSandbox.currentProjectId();
    if (!projectId) { return [ROOT_EMAILS_PATH]; }
    return [ROOT_EMAILS_PATH, projectId];
  });
    
  constructor(
    private readonly router: Router,
    private readonly globalSandbox: IrisGlobalSandbox,
    private readonly emailsNavigationSandbox: IrisEmailsNavigationSandbox,
  ) {}
  
  openRootPage(): Promise<boolean> {
    return this.router.navigate([ROOT_EMAILS_PATH]);
  }

  openSendEmailPage(extras?: NavigationExtras): Promise<boolean> {
    const basePathSegments = this.baseEmailsPath();
    const selectedFolderId = this.emailsNavigationSandbox.selectedFolderId();
    return this.router.navigate([
      ...basePathSegments,
      selectedFolderId ?? EmailCategory.Inbox.toLowerCase(),
      NEW_MESSAGE_PATH_NAME,
    ], extras);
  }

  openForwardEmailPage(forwardedMessageId: string): Promise<boolean> {
    return this.openSendEmailPage({ queryParams: { [FORWARDED_MESSAGE_QUERY_NAME]: forwardedMessageId } });
  }

  openReplyToEmailPage(repliedMessageId: string, replyAll?: boolean): Promise<boolean> {
    return this.openSendEmailPage({ queryParams: {
      [REPLIED_MESSAGE_QUERY_NAME]: repliedMessageId,
      [REPLY_ALL_QUERY_NAME]: replyAll,
    } });
  }

  openInboxPage(extras?: NavigationExtras): Observable<boolean> {
    return this.emailsNavigationSandbox.inboxNavigationItemId$.pipe(
      filter(Boolean),
      take(1),
      switchMap(inboxNavigationItemId => this.router.navigate([
        ...this.baseEmailsPath(),
        inboxNavigationItemId,
      ], extras)),
    );
  }

  openDraftPage(): Promise<boolean> {
    const basePathSegments = this.baseEmailsPath();
    const draftNavigationItemId = this.emailsNavigationSandbox.draftNavigationItemId();
    return this.router.navigate([
      ...basePathSegments,
      draftNavigationItemId,
    ]);
  }

  closeSelectedEmail(): Promise<boolean> {
    const basePathSegments = this.baseEmailsPath();
    const selectedFolderId = this.emailsNavigationSandbox.selectedFolderId();
    return this.router.navigate([
      ...basePathSegments,
      selectedFolderId,
    ]);
  }

  openParentPage(route: ActivatedRoute): Promise<boolean> {
    return this.router.navigate(['..'], { relativeTo: route });
  }

  openMainPage(): Promise<boolean> {
    return this.router.navigate(this.baseEmailsPath());
  }

  openDocumentsPage(extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate([
      ...this.baseEmailsPath(),
      IrisEmailsTabType.Documents.toLowerCase(),
    ], extras);
  }

  openPageByFolderId(folderId: string, extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate([
      ...this.baseEmailsPath(),
      folderId,
    ], extras);
  }
  
  openMessagePage(message: IrisEmailMessageI, extras?: NavigationExtras): Promise<boolean> {
    const commands = [
      ...this.baseEmailsPath(),
      message.parentFolderId,
    ];

    if (message.isDraft) {
      commands.push(DRAFT_MESSAGE_PATH_NAME);
    }

    commands.push(message.id);
    return this.router.navigate(commands, extras);
  }
}
