import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { IrisEmailSettingsAlias, IrisEmailUserSettingsI, IrisEmailUserSettingsResponseI } from '../../models/IrisEmailSettings';
import { EmailWellKnownFolderName } from '../../models/IrisEmailNavigation';
import { inject } from '@angular/core';
import { IrisUserSettingsService } from '@iris/common/services/user-settings.service';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { mergeMap, pipe, switchMap, tap } from 'rxjs';
import { IrisEmailsService } from '../../services/emails.service';

export interface IrisEmailsGlobalState {
  userSettings: IrisEmailUserSettingsI;
  folderShortcutMap: Map<EmailWellKnownFolderName, string>;
  unreadCount: number;
}

const initialState: IrisEmailsGlobalState = {
  userSettings: {},
  folderShortcutMap: new Map<EmailWellKnownFolderName, string>(),
  unreadCount: 0,
};

export const IrisEmailsGlobalStore = signalStore(
  { providedIn: 'root' },
  withState<IrisEmailsGlobalState>(initialState),
  withMethods(store => ({
    getFolderIdByShortcut: (shortcut: EmailWellKnownFolderName) => store.folderShortcutMap().get(shortcut),
  })),
  withMethods((
    store,
    userSettingsService = inject(IrisUserSettingsService),
    emailsService = inject(IrisEmailsService),
  ) => ({
    fetchUserSettings$: rxMethod<number>(
      pipe(
        switchMap(userId =>
          userSettingsService
            .getUserSettingsById<IrisEmailUserSettingsResponseI>(IrisEmailSettingsAlias.global, userId)
            .pipe(
              tap(response => patchState(store, { userSettings: response.settings })),
            ),
        ),
      ),
    ),
    fetchFolderByShortcut$: rxMethod<EmailWellKnownFolderName>(
      pipe(
        mergeMap(folderShortcut => emailsService.getCustomFolder(folderShortcut).pipe(
          tap(folder => patchState(store, { folderShortcutMap: store.folderShortcutMap().set(folderShortcut, folder.id) } )),
        )),
      ),
    ),
    fetchUnreadCount$: rxMethod<void>(
      pipe(
        mergeMap(() => emailsService.getUnreadCount().pipe(
          tap(unreadCount => patchState(store, { unreadCount })),
        )),
      ),
    ),
  })),
);
