import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { IrisEmailsService } from '../../services/emails.service';
import {
  RemoveEmailMessageSuccess,
  RemoveSelectedEmailMessagesSuccess,
  MoveMessagesToFolderSuccess,
  FetchMessagesRangeStart,
  EmailsMessageChangeEvent,
  SetMessageReadMarkBulkSuccess,
  MarkMessageAsReadSuccess,
  MarkMessageAsUnreadSuccess,
  ForwardOrReplyMessageSuccess,
} from './emails-messages.actions';
import { switchMap, map, catchError, of, filter, bufferTime } from 'rxjs';
import { IrisAlertService } from '@iris/common/modules/alert/service/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { EmailMessageChangeEventI, EmailMesssageChangeActionType } from '../../models/IrisEmail';
import { IrisEmailsRoutingService } from '@iris/modules/emails/services/emails-routing.service';
import { isEqual, isNil, isNumber, uniq, uniqWith } from 'lodash';
import { IrisEmailsNavigationStore } from '../navigation/emails-navigation.signal-store';
import { IrisEmailsMessagesStore } from './emails-messages.signal-store';
import { IrisEmailsGlobalStore } from '../global/emails-global.signal-store';

@Injectable()
export class IrisEmailsMessagesEffects {
  private readonly emailsNavigationStore = inject(IrisEmailsNavigationStore);
  private readonly emailsMessagesStore = inject(IrisEmailsMessagesStore);
  private readonly emailsGlobalStore = inject(IrisEmailsGlobalStore);

  readonly updateSelectedFolderOnAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkMessageAsReadSuccess, MarkMessageAsUnreadSuccess, SetMessageReadMarkBulkSuccess),
      switchMap(() => {
        this.emailsNavigationStore.fetchCustomFolder$(this.emailsNavigationStore.selectedFolderId());
        return [];
      }),
    ),
  );

  readonly selectedMessageRemoved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveEmailMessageSuccess),
      switchMap(action => {
        const selectedMessageId = this.emailsMessagesStore.selectedMessageId();
        const pagination = this.emailsMessagesStore.pagination();
        const subject = action.message.subject ? `"${action.message.subject}"` : '';
        this.alertify.success(this.translateService.instant('text.email.EmailMessageRemoved', { subject }));
        if (action.message.id === selectedMessageId) {
          void this.emailsRoutingService.closeSelectedEmail();
        }
        this.emailsNavigationStore.fetchCustomFolder$(action.message.parentFolderId);
        const actions: Action<string>[] = [
          FetchMessagesRangeStart({ offset: pagination.offset + pagination.limit - 1, limit: 1 }),
        ];

        if (action.message.isDraft) {
          this.emailsMessagesStore.setOpenedDraftMessage(null);
        }

        return actions;
      }),
    ),
  );

  readonly selectedMessagesRemoved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveSelectedEmailMessagesSuccess),
      map(({ selectedMessagesCount, selectedFolderId }) => {
        const pagination = this.emailsMessagesStore.pagination();
        this.alertify.success(this.translateService.instant('text.email.EmailsMessagesRemoved', { count: selectedMessagesCount }));
        this.emailsNavigationStore.fetchCustomFolder$(selectedFolderId);
        this.emailsMessagesStore.resetPagination();
        return FetchMessagesRangeStart({ offset: pagination.offset + pagination.limit - selectedMessagesCount, limit: selectedMessagesCount });
      }),
    ),
  );

  readonly messagesMovedToFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MoveMessagesToFolderSuccess),
      switchMap(({ targetFolderId, sourceFolderId }) => {
        this.emailsNavigationStore.fetchCustomFolder$(targetFolderId);
        this.emailsNavigationStore.fetchCustomFolder$(sourceFolderId);
        return [];
      }),
    ),
  );

  readonly messageForwarded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForwardOrReplyMessageSuccess),
      switchMap(({ forwardedMessage, message, silent }) => {
        const selectedFolderId = this.emailsNavigationStore.selectedFolderId();
        if (!silent) {
          this.emailsMessagesStore.setOpenedDraftMessage(forwardedMessage);
        }
        if (forwardedMessage.parentFolderId === selectedFolderId && !message.id) {
          this.emailsNavigationStore.fetchCustomFolder$(forwardedMessage.parentFolderId);
          this.emailsMessagesStore.fetchMessageAttachments$({ messageId: forwardedMessage.id });
        }
        return [];
      }),
    ),
  );

  fetchMessagesRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FetchMessagesRangeStart),
      filter(({ offset }) => {
        const selectedFolderId = this.emailsNavigationStore.selectedFolderId();
        const totalItemsCount = this.emailsMessagesStore.totalItemsCount();
        return !!selectedFolderId
          && (isNil(totalItemsCount) || offset <= totalItemsCount);
      }),
      switchMap(({ offset, limit }) => {
        const selectedFolderId = this.emailsNavigationStore.selectedFolderId();
        const pagination = this.emailsMessagesStore.pagination();
        const paginationRequest = { ...pagination, offset, limit };

        return this.emailsService.getMessagesByAlias(selectedFolderId, paginationRequest).pipe(
          switchMap(({ count, currentPage }) => {
            this.emailsMessagesStore.addMessages({ messages: currentPage, replace: false, offset, limit });
            if (isNumber(count)) {
              this.emailsMessagesStore.setTotalItemsCount(count);
            }
    
            return [];
          }),
          catchError(() => {
            this.emailsMessagesStore.addMessages({ messages: [], replace: false });
            return of();
          }),
        );
      }),
    ),
  );

  emailsMessageChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailsMessageChangeEvent),
      bufferTime(300),
      filter(events => !!events.length),
      switchMap(actions => {
        const selectedFolderId = this.emailsNavigationStore.selectedFolderId();
        const pagination = this.emailsMessagesStore.pagination();
        const events = uniqWith<EmailMessageChangeEventI>(actions.map(action => action.event), isEqual);
        const uniqFoldersIds = uniq(events.map(event => event.parentFolderId).filter(Boolean));

        this.emailsGlobalStore.fetchUnreadCount$();
        const actionsToDispatch: Action<string>[] = [];
        const foldersReqestsLimit = 5;
        if (uniqFoldersIds.length > foldersReqestsLimit) {
          this.emailsNavigationStore.fetchCustomFolders$();
        } else {
          uniqFoldersIds.map(folderId => this.emailsNavigationStore.fetchCustomFolder$(folderId));
        }

        if (uniqFoldersIds.includes(selectedFolderId)) {
          const currentFolderEvents = events.filter(event => event.parentFolderId === selectedFolderId);
          currentFolderEvents.forEach(event => {
            switch(event.changeType) {
              case EmailMesssageChangeActionType.Deleted:
                this.emailsMessagesStore.removeMessage$(event.id);
                break;
              case EmailMesssageChangeActionType.Created:
                actionsToDispatch.push(
                  FetchMessagesRangeStart({
                    offset: pagination.offset,
                    limit: pagination.limit,
                  }),
                );
                break;
              case EmailMesssageChangeActionType.Updated:
                this.emailsMessagesStore.fetchMessage$(event.id);
                break;
            }
          });
        }

        return actionsToDispatch;
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly emailsService: IrisEmailsService,
    private readonly alertify: IrisAlertService,
    private readonly translateService: TranslateService,
    private readonly emailsRoutingService: IrisEmailsRoutingService,
  ) {}
}
