import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IrisDateAgoPipeModule } from './pipes/date-ago/date-ago.module';
import { SafeHtmlPipeModule } from './pipes/dom-sanitizer/safe-html.module';
import { NgLatinizePipeModule } from './latinize/latinize.pipe';
import { FilterByPipe } from '@iris/common/modules/pipes-common/pipes/array-utils/filterBy.pipe';
import { ExcludePipe } from '@iris/common/modules/pipes-common/pipes/array-utils/exclude.pipe';
import { IrisTranslatePipe } from './pipes/translate/translate.pipe';
import { IrisFilterFieldPipe } from './pipes/filter-field/filter-field.pipe';
import { IrisFileSizePipe } from './pipes/filesize/file-size.pipe';
import { IrisTransformPipe } from './pipes/transform/transform.pipe';
import { IrisHighlightTextPipe } from './pipes/highlight-text/highlight-text.pipe';
import { IrisHighlightUrlPipe } from './pipes/highlight-url/highlight-url.pipe';
import { SafeResourceUrlPipe } from './pipes/safe-resource-url/safe-resource-url.pipe';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { ColorIsDarkPipe } from './pipes/color-is-dark/color-is-dark.pipe';
import { IrisUnitsPipe } from './pipes/units/units.pipe';
import { IrisDateAgoPipe } from './pipes/date-ago/date-ago.pipe';
import { IrisTranslationPipe } from '@iris/common/modules/pipes-common/pipes/translate/translation.pipe';

@NgModule({
  imports: [
    CommonModule,
    SafeHtmlPipeModule,
    IrisDateAgoPipeModule,
    NgLatinizePipeModule,
    IrisTranslatePipe,
    IrisTranslationPipe,
    ExcludePipe,
    FilterByPipe,
  ],
  declarations: [
    IrisFilterFieldPipe,
    IrisFileSizePipe,
    IrisTransformPipe,
    IrisHighlightTextPipe,
    IrisHighlightUrlPipe,
    SafeResourceUrlPipe,
    TruncatePipe,
    ColorIsDarkPipe,
    IrisUnitsPipe,
  ],
  exports: [
    IrisFilterFieldPipe,
    IrisFileSizePipe,
    IrisTransformPipe,
    IrisTranslatePipe,
    IrisTranslationPipe,
    IrisHighlightTextPipe,
    IrisHighlightUrlPipe,
    SafeResourceUrlPipe,
    TruncatePipe,
    ColorIsDarkPipe,
    IrisUnitsPipe,
    IrisDateAgoPipe,
  ],
  providers: [
    IrisFilterFieldPipe,
    IrisFileSizePipe,
    IrisTranslatePipe,
    IrisTranslationPipe,
    IrisHighlightTextPipe,
    IrisHighlightUrlPipe,
    SafeResourceUrlPipe,
    TruncatePipe,
    ColorIsDarkPipe,
    IrisUnitsPipe,
  ],
})
export class IrisCommonPipesModule {
}
