import { Injectable } from '@angular/core';
import { WebsocketService } from './websocket.service';
import { IMessage } from '@stomp/stompjs';
import { Observable, Subscription, map, tap } from 'rxjs';
import { EmailsMessageChangeEvent } from '@iris/common/modules/emails/sandbox/messages/emails-messages.actions';
import { Store } from '@ngrx/store';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';
import { EmailMessageChangeEventI } from '@iris/common/modules/emails/models/IrisEmail';

@Injectable({ providedIn: 'root' })
export class EmailsWebsocketService {
  private userSubscription: Subscription;

  constructor(
    private readonly websocketService: WebsocketService,
    private readonly store: Store,
    private readonly envService: IrisEnvironmentService,
  ) {}

  get instanceId(): string {
    return this.envService.env.instanceId;
  }

  get instanceIdUrlPart(): string {
    return this.instanceId ? `/${this.instanceId}` : '';
  }

  connectToUserWs(userId: string): Observable<IMessage> {
    return this.websocketService.watchNotificationsWs(`/instance-broker${this.instanceIdUrlPart}/mail-client/users/${userId}`);
  }

  public subscribeToUser(userId: string): void {
    if (this.userSubscription) {
      this.unsubscribeFromUser();
    }
    this.userSubscription = this.connectToUserWs(userId)
      .pipe(
        map(({ body }) => JSON.parse(body) as EmailMessageChangeEventI),
        tap((event: EmailMessageChangeEventI) => {
          this.store.dispatch(EmailsMessageChangeEvent({ event }));
        }),
      ).subscribe();
  }

  public unsubscribeFromUser(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
  }
}
