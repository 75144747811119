import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { IrisMenuItemI } from '../models/IrisMenuItem';
import { simpleDeepCopy } from '../utils/object.utils';
import { MatSidenav } from '@angular/material/sidenav';
import { WebsocketService } from '@iris/common/services/websockets/websocket.service';
import { IMessage } from '@stomp/stompjs';
import { IrisTranslatePipe } from '@iris/common/modules/pipes-common/pipes';
import { ApiUrl, IrisApiClient } from '@iris/api-query';
import { isEmpty } from 'lodash';

@Injectable()
@ApiUrl('/security/menu/v2')
export class IrisMainMenuService extends IrisApiClient<IrisMenuItemI> {

  private readonly menu: IrisMenuItemI[] = [];
  private readonly currentModuleUrl = document.location.pathname;
  private sidenavMenu: MatSidenav;
  private readonly pinnedChange = new ReplaySubject<boolean>(1);
  private readonly _sidenavSubject = new Subject<MatSidenav>();
  sidenav$ = this._sidenavSubject.asObservable();

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly irisTranslate: IrisTranslatePipe,
    private readonly translateService: TranslateService,
    private readonly websocketService: WebsocketService,
  ) {
    super(_httpClient);
  }

  set sidenav(sidenav: MatSidenav) {
    this.sidenavMenu = sidenav;
    this._sidenavSubject.next(sidenav);
  }

  get sidenav(): MatSidenav {
    return this.sidenavMenu;
  }

  get pinnedState$(): Observable<boolean> {
    return this.pinnedChange.asObservable();
  }

  updatePinnedState(pinned: boolean): void {
    this.pinnedChange.next(pinned);
  }

  @ApiUrl('~/left')
  getLeftMenu(projectId: number): Observable<IrisMenuItemI[]> {
    const options: { params?: { projectId: number } } = {};
    if (projectId) {
      options.params = { projectId };
    }
    return this._httpClient.get<IrisMenuItemI[]>(this.url(), options);
  }

  listenLeftMenuUpdateByProjects(): Observable<number[]> {
    return this.websocketService.connect(`/global-broker/menu-with-projects`).pipe(
      map((answer: IMessage) => JSON.parse(answer.body)),
      map((body: Record<string, number[]>) => (body?.projectIds ?? [])),
    );
  }

  getMenu(): IrisMenuItemI[] {
    return simpleDeepCopy(this.menu);
  }

  @ApiUrl('~/config')
  getConfigMenu(): Observable<IrisMenuItemI[]> {
    return this._httpClient.get<IrisMenuItemI[]>(this.url());
  }

  @ApiUrl('~/main')
  getMenuItems(): Observable<IrisMenuItemI[]> {
    return this._httpClient.get<IrisMenuItemI[]>(this.url());
  }

  @ApiUrl('~/items')
  getMenuConfiguration(): Observable<IrisMenuItemI[]> {
    return this._httpClient.get<IrisMenuItemI[]>(this.url());
  }

  @ApiUrl('~/{sourceMenuId}/exchange-order/{targetMenuId}')
  updateMenuOrder(sourceMenuId: number, targetMenuId: number): Observable<IrisMenuItemI[]> {
    return this._httpClient.post<IrisMenuItemI[]>(this.url({ sourceMenuId, targetMenuId }), null);
  }

  @ApiUrl('~/{menuId}/change-show-in-menu/{visible}')
  toggleMenuItem(menuId: number, visible: boolean): Observable<IrisMenuItemI[]> {
    return this._httpClient.post<IrisMenuItemI[]>(this.url({ menuId, visible }), null);
  }

  processMenuItemsForSelector(menuItems: IrisMenuItemI[] = [], parent?: IrisMenuItemI): IrisMenuItemI[] {
    const pages = [];

    for (const item of menuItems) {
      if (!item.url) { continue; }

      if (parent?.title) {
        item.title = `${ parent.title } - ${ item.title }`;
      } else if (item.nameTranslated) {
        item.title = this.translateService.instant(item.nameTranslated);
      } else if (!isEmpty(item.translations)) {
        item.title = this.irisTranslate.transform(this.translateService.instant(item.shortTitle || item.title), item.translations).name;
      } else {
        item.title = this.translateService.instant(item.title);
      }

      pages.push(item);

      if (Array.isArray(item.children) && item.children.length > 0) {
        pages.push(...this.processMenuItemsForSelector(item.children, item));
      }
    }
    return pages;
  }

  prepareMenuFromData(data: IrisMenuItemI[]): IrisMenuItemI[] {
    return data.map((mainItem: IrisMenuItemI) => {
      mainItem.visible = true;
      mainItem.title = mainItem.title && this.translateService.instant(mainItem.title);
      mainItem.shortTitle = mainItem.shortTitle && this.translateService.instant(mainItem.shortTitle);
      if (mainItem.children) {
        mainItem.showInQuickNavigation = false;
        mainItem.isActive = false;
        this._processMenuChildren(mainItem);
      } else {
        mainItem.isActive = this.currentModuleUrl.startsWith(mainItem.url);
      }
      return mainItem;
    });
  }

  private _processMenuChildren(menuItem: IrisMenuItemI): void {
    if (menuItem.children) {
      menuItem.children.forEach(item => {
        item.title = item.title && this.translateService.instant(item.title);
        item.shortTitle = item.shortTitle && this.translateService.instant(item.shortTitle);
        if (item.showInQuickNavigation) {
          menuItem.showInQuickNavigation = true;
        }
        item.isActive = this.currentModuleUrl.startsWith(item.url);
        if (item.isActive) {
          menuItem.isActive = true;
        }
        this._processMenuChildren(item);
      });
    }
  }

}
