import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, Subject, switchMap } from 'rxjs';
import { EmailWellKnownFolderName } from '../../models/IrisEmailNavigation';
import { IrisEmailsService } from '../../services/emails.service';
import { IrisAuthService } from '@iris/modules/auth/utils/auth.service';
import { IrisUserService } from '@iris/common/services/user.service';
import { IrisEmailsGlobalStore } from './emails-global.signal-store';

@Injectable({ providedIn: 'root' })
export class IrisEmailsGlobalSandbox {
  private readonly emailsGlobalStore = inject(IrisEmailsGlobalStore);

  readonly unreadCount = this.emailsGlobalStore.unreadCount;

  readonly view$: BehaviorSubject<string> = new BehaviorSubject<string>('desktop-view');

  private readonly scrollMessagesToSubject = new Subject<ScrollToOptions>();

  readonly messagesScrolled$ = this.scrollMessagesToSubject.asObservable();

  private readonly viewMap = new Map([
    [Breakpoints.Small, 'mobile-view'],
    [Breakpoints.Medium, 'tablet-view'],
    [Breakpoints.Large, 'desktop-view'],
  ]);

  constructor(
    protected readonly breakpointObserver: BreakpointObserver,
    private readonly emailsService: IrisEmailsService,
    private readonly authService: IrisAuthService,
    private readonly userService: IrisUserService,
  ) {
    breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large])
      .pipe(
        map((result) => {
          for (const query of Object.keys(result.breakpoints)) {
            if (result.breakpoints[query]) {
              this.view$.next(this.viewMap.get(query) ?? 'desktop-view');
            }
          }
        }),
      )
      .subscribe();
  }

  get user(): IdentUserI {
    return this.userService.me;
  }

  get userId(): number {
    return this.user?.id;
  }

  getUserSettings(): void {
    this.emailsGlobalStore.fetchUserSettings$(this.userId);
  }

  getFolderByShortcut(folderShortcut: EmailWellKnownFolderName): void {
    this.emailsGlobalStore.fetchFolderByShortcut$(folderShortcut);
  }

  getInboxFolderId(): void {
    this.getFolderByShortcut(EmailWellKnownFolderName.Inbox);
  }

  getDraftFolderId(): void {
    this.getFolderByShortcut(EmailWellKnownFolderName.Drafts);
  }

  getSentFolderId(): void {
    this.getFolderByShortcut(EmailWellKnownFolderName.SentItems);
  }

  getDeletedFolderId(): void {
    this.getFolderByShortcut(EmailWellKnownFolderName.DeletedItems);
  }

  checkUserAccessToSharedMailbox(): Observable<boolean> {
    return this.authService.checkSSO(this.user.email).pipe(
      switchMap((ssoInfo) => {
        if (!ssoInfo.singleSignOnActive) {
          return of(false);
        }
        return this.emailsService.checkUserAccess();
      }),
    );
  }

  scrollMessagesTo(left: number, top: number): void {
    this.scrollMessagesToSubject.next({ left, top });
  }

  getUnreadCount(): void {
    this.emailsGlobalStore.fetchUnreadCount$();
  }
}
