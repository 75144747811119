import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NestedTreeControl } from '@angular/cdk/tree';
import { DOCUMENT } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { IrisMenuItemI } from '@iris/common/models/IrisMenuItem';
import { MenuItemNode } from '@iris/common/modules/global-navigation-menu/models/menu-item-node';
import { IrisTranslatePipe } from '@iris/common/modules/pipes-common/pipes/translate/translate.pipe';
import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { IrisInfrastructureService } from '@iris/common/services/infrastructure.service';
import { IrisMainMenuService } from '@iris/common/services/main-menu.service';
import { IrisLocalStorageService } from '@iris/common/services/utils/iris-local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import isNil from 'lodash/isNil';
import { combineLatest, of, Subject } from 'rxjs';
import { getUi2SubPathOrNull } from '@iris/common/utils/url.utils';
import { catchError, debounceTime, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { MatDrawerMode } from '@angular/material/sidenav';
import { EmailsWebsocketService } from '@iris/common/services/websockets/emails-websocket.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IrisEmailsGlobalSandbox } from '@iris/common/modules/emails/sandbox/global/emails-global.sandbox';
import { IrisAnnouncementsService } from '@iris/modules/administration/administration-list/announcement-page/services/announcements.service';

@Component({
  selector: 'global-navigation-menu',
  templateUrl: './global-navigation-menu.component.html',
  styleUrls: ['./global-navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideVertical', [
      state(
        '*',
        style({
          height: 0,
        }),
      ),
      state(
        'show',
        style({
          height: '*',
        }),
      ),
      transition('* => *', [animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')]),
    ]),
  ],
})
export class IrisGlobalNavigationMenuModuleComponent implements OnInit {

  @ViewChild('scrollBox', { read: ElementRef }) scrollBox: ElementRef;
  @ViewChildren(MatTooltip) tooltips!: QueryList<MatTooltip>;

  @Input() set sidenavMode(mode: MatDrawerMode) {
    this._sidenavMode = mode;
    this.pinned = mode === 'side';
    this.hasBackdrop = !this.pinned;
  }

  get sidenavMode(): MatDrawerMode {
    return this._sidenavMode;
  }

  @Output() sidenavModeChange = new EventEmitter<MatDrawerMode>();

  navigationMenu: MenuItemNode[] = [];
  _sidenavMode: MatDrawerMode;
  pinned: boolean;
  hasBackdrop: boolean;
  navigationMenuTreeControl = new NestedTreeControl<MenuItemNode>((node) => node.children);
  navigationMenuDataSource = new MatTreeNestedDataSource<MenuItemNode>();
  public readonly emailUnreadMessagesCount = this.emailsGlobalSandbox.unreadCount;

  private readonly delayBeforeOpen = 2000;
  private readonly disquiseHintTimeout = 1000;

  private readonly menuChangedSubject = new Subject<number>();

  private timeoutIdOnMouseEnter: number;
  private timeoutIdOnMouseLeave: number;
  private openedOnMouseEnter = false;

  get navigationMenuDS(): MatTreeNestedDataSource<MenuItemNode> {
    return this.navigationMenuDataSource;
  }

  constructor(
    private readonly router: Router,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly storage: IrisLocalStorageService,
    private readonly menuService: IrisMainMenuService,
    private readonly translate: TranslateService,
    private readonly irisTranslate: IrisTranslatePipe,
    private readonly hotkeysService: HotkeysService,
    private readonly infrastructureService: IrisInfrastructureService,
    private readonly globalSandbox: IrisGlobalSandbox,
    private readonly emailsGlobalSandbox: IrisEmailsGlobalSandbox,
    private readonly emailsWebsocketService: EmailsWebsocketService,
    private readonly destroyRef: DestroyRef,
    private readonly irisAnnouncementsService: IrisAnnouncementsService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),

      tap((event: NavigationEnd) => {
        if (!event.url?.length || !this.navigationMenu?.length) { return; }

        const currentUrl = this.document.location?.pathname ?? `/ui/ui2${event.url}`;

        const isLeftMenuItemChosen = this.navigationMenu.some((menuItem) => {
          if (menuItem.children?.length) {
            return menuItem.children.some((subItem) => currentUrl.startsWith(subItem.url));
          }
          return currentUrl.startsWith(menuItem.url);
        });

        if (isLeftMenuItemChosen) { return; }

        this._deactivateMenuItems(this.navigationMenu);
        this.changeDetector.markForCheck();
      }),

      tap(() => {
        const currentPageUrl = this.document.location.pathname;
        if (this.isSidebarOpened()) {
          if (!this.pinned && this.isSidebarOver()) {
            void this.menuService.sidenav.close();
          }
        }
        this.setActiveItem(this.navigationMenuDataSource.data, currentPageUrl);
        this.changeDetector.markForCheck();
      }),
      debounceTime(1000),
      tap(() => this.scrollToActiveItem()),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    this.hotkeysService.add([
      new Hotkey(['up', 'down'], (event: KeyboardEvent): boolean => {
        return !this.navigate(event.code === 'ArrowDown' ? 1 : -1);
      }),
    ]);
  }

  ngOnInit(): void {
    this.handleEmailClientInboxCounter();

    this.menuChangedSubject.pipe(
      switchMap(projectId => {
        return combineLatest([
          this.menuService.getLeftMenu(projectId),
          this.irisAnnouncementsService.getAnnouncementConfigs(true).pipe(
            catchError(() => of([])),
          ),
        ]).pipe(
          map(([menu, activeAnnouncementConfigs]) => {
            activeAnnouncementConfigs.forEach(config => {
              let template: IrisMenuItemI;
              menu.filter(item => item.moduleType === 'DYNAMIC_FORMS').forEach(item => {
                template = item.children.find(item => item.url.endsWith(`/${config.documentTemplateId}`));
                if (template) {
                  template.newFunctionalityConfig = config;
                }
              });
            });

            return menu;
          }),
        );
      }),
      catchError((_e: Error) => of([])),
      filter(menu => !!menu.length),
      tap((menu) => {
        this.navigationMenu = menu
          .filter(menuItem => menuItem.showInQuickNavigation)
          .map(menuItem => {
            const menuItemNode: MenuItemNode = {
              ...menuItem,
              $title: this.getTitle(menuItem),
            };

            if (menuItem.children) {
              menuItemNode.$hash = this.getHash(menuItem);

              menuItemNode.children = menuItem.children
                .filter(child => child.showInQuickNavigation)
                .map(child => {
                  const childNode: MenuItemNode = {
                    ...child,
                    $title: this.getTitle(child),
                  };

                  return childNode;
                });
            }

            return menuItemNode;
          });

        this.navigationMenuDataSource.data = this.navigationMenu;

        // After reloading the page, we need to expand all the menus that have been expanded before
        this.navigationMenuDataSource.data.forEach(node => {
          if (this.isExpanded(node) && !this.navigationMenuTreeControl.isExpanded(node)) {
            this.toggleSubMenu(node);
          }
        });

        const currentPageUrl = this.document.location.pathname;
        this.setActiveItem(this.navigationMenuDataSource.data, currentPageUrl);

        this.changeDetector.markForCheck();
      }),
      // Timer for navigationMenu view initialization
      debounceTime(1000),
      tap(() => this.scrollToActiveItem()),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    this.globalSandbox.currentProjectId$.pipe(
      tap((projectId) => this.menuChangedSubject.next(projectId)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    this.menuService.listenLeftMenuUpdateByProjects().pipe(
      withLatestFrom(this.globalSandbox.currentProjectId$),
      tap(([updatedProjectIds, currentProjectId]) => {
        if (!!currentProjectId && updatedProjectIds.includes(currentProjectId)) {
          this.menuChangedSubject.next(currentProjectId);
        }
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  isSidebarOpened(): boolean {
    return this.menuService.sidenav?.opened;
  }

  showTooltipIfNeeded(item: MenuItemNode): string {
    return !this.isSidebarOpened() || item.$title.length > 24 ? item.$title : '';
  }

  public close(): void {
    if (this.isSidebarOver()) {
      this.clearMouseTimeouts();
      void this.menuService.sidenav.close();
    }
  }

  public isSidebarOver(): boolean {
    return this.sidenavMode === 'over';
  }

  private getTitle(menuItem: IrisMenuItemI): string {
    if (menuItem.moduleType.includes('IRIS_MODULE')) {
      return this.irisTranslate.transform(this.translate.instant(menuItem.shortTitle || menuItem.title), menuItem.translations?.name);
    } else {
      return menuItem.nameTranslated;
    }
  }

  public hasChild(_: number, node: MenuItemNode): boolean {
    return !!node.children && node.children.length > 0;
  }

  scrollToActiveItem(): void {
    const activeElements = this.document.querySelectorAll(`.iris-menu-list .menu-item-container.active`);
    const currentElement = activeElements[activeElements.length - 1];

    if (activeElements && activeElements.length) {
      if (this.infrastructureService.isTablet) {
        const safeOffset = 140;
        const scrollElement = this.scrollBox.nativeElement;
        const relativeElementTop = currentElement.getBoundingClientRect().top;
        const targetOffset = relativeElementTop + scrollElement.scrollTop - safeOffset;
        scrollElement.scroll(0, targetOffset);
      } else {
        currentElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }
  }

  private clearMouseTimeouts(): void {
    if (this.timeoutIdOnMouseLeave) {
      clearTimeout(this.timeoutIdOnMouseLeave);
    }

    if (this.timeoutIdOnMouseEnter) {
      clearTimeout(this.timeoutIdOnMouseEnter);
    }
  }

  public mouseEnter(): void {
    if (this.infrastructureService.isTablet) { return; }
    this.clearMouseTimeouts();

    if (!this.isSidebarOver() || this.isSidebarOpened()) {
      return;
    }

    this.timeoutIdOnMouseEnter = window.setTimeout(() => {
      void this.menuService.sidenav.open();
      this.openedOnMouseEnter = true;
      this.hideTooltips();
    }, this.delayBeforeOpen);
  }

  public mouseLeave(): void {
    if (this.infrastructureService.isTablet) { return; }
    this.clearMouseTimeouts();

    if (!this.isSidebarOver() || !this.isSidebarOpened() || !this.openedOnMouseEnter) {
      return;
    }

    this.timeoutIdOnMouseLeave = window.setTimeout(() => {
      void this.menuService.sidenav.close();
      this.openedOnMouseEnter = false;
      this.hideTooltips();
      this.changeDetector.markForCheck();
    }, 400);
  }

  public handleMenuClick($event, menuItem: MenuItemNode): boolean {
    if (menuItem.$active) {
      $event.preventDefault();
      return false;
    }
    if ($event.metaKey || $event.ctrlKey) {
      return true;
    }
    this.setActiveItem(this.navigationMenuDataSource.data, menuItem.url);

    if (this.hasBackdrop) {
      void this.menuService.sidenav.close();
    }

    this.disguiseHints();
    return true;
  }

  public disguiseHints(): void {
    if (this.infrastructureService.isTablet) {
      setTimeout(() => this.hideTooltips(), this.disquiseHintTimeout);
    }
    return;
  }

  private hideTooltips(): void {
    this.tooltips.forEach((tooltip) => {
      tooltip.hide();
    });
  }

  public togglePinned(pinned: boolean): void {
    this.menuService.updatePinnedState(pinned);

    if (pinned) {
      this.sidenavModeChange.emit('side');
      this.hasBackdrop = false;
    } else {
      this.sidenavModeChange.emit('over');
      this.hasBackdrop = true;
    }

    this.changeDetector.markForCheck();
  }

  private navigate(step: number): boolean {
    if (this.isSidebarOpened()) {
      let items: HTMLElement[] = Array.from(this.document.querySelectorAll('.iris-menu-list .menu-item'));

      if (!items.length) {
        return false;
      }

      items = items.filter((item) => {
        const groupContainer: HTMLElement = item.closest('.menu-group-container');
        if (!groupContainer) {
          return true;
        }
        return !!item.closest('.menu-item-container.expanded');
      });

      const currentFocusedElement = this.document.activeElement;
      const totalItems = items.length;

      let nextIndex: number;
      let currentIndex = items.findIndex((item) => item == currentFocusedElement);

      if (currentIndex === -1) {
        nextIndex = step > 0 ? 0 : totalItems - 1;
      } else {
        currentIndex += step;
        currentIndex %= totalItems;
        nextIndex = currentIndex < 0 ? (totalItems - 1) : currentIndex;
      }

      items[nextIndex].focus();

      return true;
    }

    return false;
  }

  private getHash(menuItem: IrisMenuItemI): number {
    const title = menuItem.shortTitle || menuItem.title;
    let h = 0xdeadbeef;

    for (let i = 0; i < title.length; i++) {
      h = Math.imul(h ^ title.charCodeAt(i), 2654435761);
    }

    return (h ^ h >>> 16) >>> 0;
  }

  public toggleSubMenu(node: MenuItemNode): void {
    if (this.navigationMenuTreeControl.isExpanded(node)) {
      this.navigationMenuTreeControl.collapse(node);
    } else {
      this.navigationMenuTreeControl.expand(node);
    }

    if (node.$hash) {
      const globalMenu = this.storage.getItem('global_menu') || {};
      globalMenu[node.$hash] = this.navigationMenuTreeControl.isExpanded(node);
      this.storage.setItem('global_menu', globalMenu);
    }
    this.disguiseHints();
  }

  private isExpanded(node: MenuItemNode): boolean {
    const globalMenu = this.storage.getItem('global_menu') || {};
    return globalMenu[node.$hash] !== null ? globalMenu[node.$hash] : false;
  }

  // duplicate for html
  public getUi2SubPathOrNull = (url: string): string => getUi2SubPathOrNull(url);

  private matchUrl(srcItemUrl: string, lastActiveUrl: string, srcCurrentPageUrl: string): boolean {
    if (isNil(srcItemUrl)) { return false; }

    const itemUrl = srcItemUrl.toLowerCase();
    const currentPageUrl = srcCurrentPageUrl.toLowerCase();
    const isLastActiveUrlDefined = !isNil(lastActiveUrl);

    return currentPageUrl.startsWith(itemUrl) &&
      (!isLastActiveUrlDefined ||
        itemUrl === currentPageUrl ||
        (isLastActiveUrlDefined && itemUrl.length > lastActiveUrl.length));
  }

  private _deactivateMenuItems(menu: MenuItemNode[]): void {
    menu.forEach((item) => {
      item.$active = false;
      if (item.children?.length) {
        item.children.forEach((subItem) => {
          subItem.$active = false;
        });
      }
    });
  }

  private setActiveItem(menu: MenuItemNode[], currentPageUrl: string): void {
    let lastActiveItem: MenuItemNode;

    menu.forEach(node => {
      if (node.children?.length) {
        node.children.forEach(childItem => {
          const lastActiveUrl = lastActiveItem ? lastActiveItem.url : null;
          if (this.matchUrl(childItem.url, lastActiveUrl, currentPageUrl)) {
            lastActiveItem = childItem;
            lastActiveItem.$parent = node;
          }
        });
      } else {
        const lastActiveUrl = lastActiveItem ? lastActiveItem.url : null;
        if (this.matchUrl(node.url, lastActiveUrl, currentPageUrl)) {
          lastActiveItem = node;
        }
      }
    });

    if (lastActiveItem) {
      this._deactivateMenuItems(menu);

      lastActiveItem.$active = true;

      if (lastActiveItem.$parent) {
        lastActiveItem.$parent.$active = true;

        if (!this.navigationMenuTreeControl.isExpanded(lastActiveItem.$parent)) {
          this.toggleSubMenu(lastActiveItem.$parent);
        }
      }
    }
  }
  
  private handleEmailClientInboxCounter(): void {
    this.globalSandbox.currentProjectId$.pipe(
      filter(Boolean),
      switchMap(projectId => this.globalSandbox.currentProject$.pipe(
        filter(project => project?.id === projectId),
        take(1),
      )),
      switchMap(project => {
        if (!project.sharedMailbox) {
          return of(false);
        }
        this.emailsWebsocketService.subscribeToUser(project.sharedMailbox);
        return this.emailsGlobalSandbox.checkUserAccessToSharedMailbox().pipe(
          filter(Boolean),
          tap(() => this.emailsGlobalSandbox.getUnreadCount()),
        );
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

}
