import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { forkJoin, from, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ApiUrl, IrisApiClient, IrisQueryParams, IrisQueryParamsBuilder, IrisQueryParamsOrderBy } from '@iris/api-query';
import { DmsSearchResult, IrisDMSFileI } from '../models/IrisDMSFile';
import { IrisFileIcon, IrisFileIconClass, IrisFileIconFillType } from '@iris/common/models/IrisFileIcon';
import {
  IrisDMSFileQueryParam,
  IrisDMSFileQueryParamAction,
} from '@iris/common/modules/dms/models/IrisDMSFileQueryParam';
import { IrisModules } from '@iris/common/models/IrisModulesEnums';
import { IrisAlertService } from '@iris/common/modules/alert/service/alert.service';
import { IrisDMSTagI } from '@iris/common/modules/dms/models/IrisDMSTag';
import {
  FileUploadInfo,
} from '@iris/common/modules/dms/components/modals/upload-files-modal/upload-files-modal.component';
import { LatLngLiteral } from 'leaflet';
import saveAs from 'file-saver';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';
import { IrisDmsFileLinkI } from '../models/IrisDmsFileLink';

export enum IrisFilePreviewType {
  Pdf = 'pdf',
  Image = 'image',
  Document = 'document',
}

export enum IrisFileFormat {
  Zip = 'ZIP',
  Revit = 'RVT',
}

export enum IrisFileOfficeType {
  EXCEL,
  ONE_NOTE,
  POWER_POINT,
  VISIO,
  WORD,
}

@Injectable()
@ApiUrl('/dms2/{iris@instanceId}/files')
export class IrisFilesService extends IrisApiClient<IrisDMSFileI> {
  private readonly DEFAULT_PREVIEW_TYPES = ['image', 'text'];
  private readonly DEFAULT_PREVIEW_SUBTYPES = ['pdf', 'vnd.dwg'];

  private readonly MESSAGE_MIME_TYPES = [
    'message/rfc822',
    'application/vnd.ms-outlook',
    'application/x-tika-msoffice',
  ];
  private readonly LEGACY_WORD_MIME_TYPES = [
    'application/msword',
  ];
  private readonly WORD_MIME_TYPES = [
    ...this.LEGACY_WORD_MIME_TYPES,
    'application/rtf',
    'application/vnd.ms-word.document.macroenabled.12',
    'application/vnd.ms-word.template.macroenabled.12',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.text-master',
    'application/vnd.oasis.opendocument.text-template',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.sun.xml.writer',
    'application/vnd.sun.xml.writer.global',
  ];
  private readonly LEGACY_EXCEL_MIME_TYPES = [
    'application/vnd.ms-excel',
  ];
  private readonly EXCEL_MIME_TYPES = [
    ...this.LEGACY_EXCEL_MIME_TYPES,
    'application/vnd.ms-excel.sheet.binary.macroenabled.12',
    'application/vnd.ms-excel.sheet.macroenabled.12',
    'application/vnd.ms-excel.template.macroenabled.12',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.spreadsheet-template',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.sun.xml.calc',
    'application/vnd.sun.xml.calc.template',
  ];
  private readonly LEGACY_POWERPOINT_MIME_TYPES = [
    'application/vnd.ms-powerpoint',
  ];
  private readonly POWERPOINT_MIME_TYPES = [
    ...this.LEGACY_POWERPOINT_MIME_TYPES,
    'application/vnd.ms-powerpoint.addin.macroenabled.12',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
    'application/vnd.ms-powerpoint.slide.macroenabled.12',
    'application/vnd.ms-powerpoint.slide.macroenabled.12',
    'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
    'application/vnd.ms-powerpoint.template.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.slide',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.sun.xml.impress',
    'application/vnd.sun.xml.impress.template',
  ];
  private readonly ONENOTE_MIME_TYPES = [
    'application/onenote',
  ];
  private readonly VISIO_MIME_TYPES = [
    'application/vnd.ms-visio.drawing',
    'application/vnd.ms-visio.viewer',
    'application/vnd.visio',
    'application/x-ms-vsto',
  ];
  private readonly ARCHIVE_MIME_TYPES = [
    'application/zip',
    'application/x-rar-compressed',
    'application/x-7z-compressed',
    'application/x-tar',
    'application/x-ace-compressed',
    'application/x-bzip',
    'application/x-bzip2',
    'application/java-archive',
    'application/vnd.ms-cab-compressed',
  ];

  private readonly LEGACY_EXCEL_EXTENSIONS = ['xls', 'xlt', 'xlm'];
  private readonly EXCEL_EDITABLE_EXTENSIONS = ['ods', 'xla', 'xlam', 'xll', 'xlsb', 'xlsm', 'xlsx', 'xltx', 'xlw'];
  private readonly EXCEL_EXTENSIONS = [...this.LEGACY_EXCEL_EXTENSIONS, ...this.EXCEL_EDITABLE_EXTENSIONS];

  private readonly LEGACY_POWERPOINT_EXTENSIONS = ['ppt', 'pot', 'pps'];
  private readonly POWERPOINT_EDITABLE_EXTENSIONS = ['odp', 'potm', 'potx', 'ppam', 'ppsm', 'ppsx', 'pptm', 'pptx', 'sldm', 'sldx'];
  private readonly POWERPOINT_EXTENSIONS = [...this.LEGACY_POWERPOINT_EXTENSIONS, ...this.POWERPOINT_EDITABLE_EXTENSIONS];

  private readonly LEGACY_WORD_EXTENSIONS = ['doc', 'dot', 'wbk'];
  private readonly WORD_EDITABLE_EXTENSIONS = ['docb', 'docm', 'docx', 'dotm', 'dotx', 'odt', 'rtf'];
  private readonly WORD_EXTENSIONS = [...this.LEGACY_WORD_EXTENSIONS, ...this.WORD_EDITABLE_EXTENSIONS];

  private readonly ZIP_EXTENSIONS = ['zip'];
  private readonly MESSAGE_EXTENSIONS = ['msg', 'eml'];
  private readonly PDF_EXTENSIONS = ['pdf'];
  private readonly PNG_EXTENSIONS = ['png'];
  private readonly JPG_EXTENSIONS = ['jpg', 'jpeg'];
  private readonly GIF_EXTENSIONS = ['gif'];
  private readonly SVG_EXTENSIONS = ['svg'];
  private readonly BIM_EXTENSIONS = ['ifc'];
  private readonly AUDIO_EXTENSIONS = ['mp3', 'ogg', 'wav'];
  private readonly VIDEO_EXTENSIONS = ['mp4', 'mov', 'qt', 'wav'];

  private readonly IMAGE_EXTENTIONS = [
    ...this.PNG_EXTENSIONS,
    ...this.JPG_EXTENSIONS,
    ...this.GIF_EXTENSIONS,
    ...this.SVG_EXTENSIONS,
    ...this.BIM_EXTENSIONS,
  ];

  private readonly OFFICE_EDIT_EXTENSIONS = [
    ...this.EXCEL_EDITABLE_EXTENSIONS,
    ...this.POWERPOINT_EDITABLE_EXTENSIONS,
    ...this.WORD_EDITABLE_EXTENSIONS,
    'one',
    'onetoc2',
    'odp',
    'wopitest',
  ];

  private readonly TEMP_OFFICE_FILE_MIME_TYPE = 'application/x-ms-owner';

  constructor(
    protected readonly httpClient: HttpClient,
    private readonly translateService: TranslateService,
    private readonly _alertify: IrisAlertService,
    private readonly envService: IrisEnvironmentService,
  ) {
    super(httpClient);
  }

  public isExcelFile (mimeType: string): boolean {
    return this.EXCEL_MIME_TYPES.includes(mimeType);
  }

  @ApiUrl('~/search')
  public searchFiles(params: IrisQueryParams, offset = 0, limit?: number, orderBy?: IrisQueryParamsOrderBy[]): Observable<DmsSearchResult> {
    const extendedParams = new IrisQueryParamsBuilder(params)
      .urlParam('offset', offset);

    if (limit) {
      extendedParams.limit(limit);
    }
    if (orderBy?.length) {
      extendedParams.urlParam('order-by', orderBy);
    }
    return this.httpClient.get<DmsSearchResult>(this.url(), { params: extendedParams.toObject() });
  }

  public getIcon(mime_type: string): string {
    if (!mime_type) { return 'fa-file'; }
    const icon = {
      'application/pdf': 'fa-file-pdf',
      'text': 'fa-file-alt',
      'image/svg+xml': 'fa-file',
      'image/png': 'fa-image',
      'image': 'fa-file-image',
      'audio': 'fa-file-audio',
      'video': 'fa-file-video',
    };
    return icon[mime_type] || icon[mime_type.split('/')[0]] || 'fa-file';
  }

  public getFileOfficeType(file: IrisDMSFileI): IrisFileOfficeType {
    if (this.isOfficeDocument(file.mimeType)) {
      const mimeType = file.mimeType.toLowerCase();
      switch (true) {
        case this.EXCEL_MIME_TYPES.includes(mimeType):
          return IrisFileOfficeType.EXCEL;
        case this.ONENOTE_MIME_TYPES.includes(mimeType):
          return IrisFileOfficeType.ONE_NOTE;
        case this.POWERPOINT_MIME_TYPES.includes(mimeType):
          return IrisFileOfficeType.POWER_POINT;
        case this.VISIO_MIME_TYPES.includes(mimeType):
          return IrisFileOfficeType.VISIO;
        default:
          return IrisFileOfficeType.WORD;
      }
    }
    return null;
  }

  public getIconType(file: IrisDMSFileI | IrisDataRepoFileI): IrisFileIconClass {
    const mimeType = file?.mimeType?.toLowerCase();

    if (!mimeType || mimeType === this.TEMP_OFFICE_FILE_MIME_TYPE) {
      return this.getIconTypeByExtension(file?.name);
    }

    const type = mimeType.split('/')[0];
    switch (true) {
      case mimeType === 'application/pdf':
        return IrisFileIconClass.Pdf;
      case this.WORD_MIME_TYPES.includes(mimeType):
        return IrisFileIconClass.Word;
      case this.EXCEL_MIME_TYPES.includes(mimeType):
        return IrisFileIconClass.Excel;
      case this.POWERPOINT_MIME_TYPES.includes(mimeType):
        return IrisFileIconClass.Powerpoint;
      case this.ARCHIVE_MIME_TYPES.includes(mimeType):
        return IrisFileIconClass.Zip;
      case this.MESSAGE_MIME_TYPES.includes(mimeType) && file.type === 'INCOMING_EMAIL':
        return IrisFileIconClass.IncomingEmail;
      case this.MESSAGE_MIME_TYPES.includes(mimeType) && file.type === 'OUTGOING_EMAIL':
        return IrisFileIconClass.OutgoingEmail;
      case this.MESSAGE_MIME_TYPES.includes(mimeType):
        return IrisFileIconClass.Message;
      case mimeType === 'image/png':
        return IrisFileIconClass.Png;
      case mimeType === 'image/jpeg':
      case mimeType === 'jpeg':
      case mimeType === 'image/jpg':
        return IrisFileIconClass.Jpg;
      case mimeType === 'image/gif':
        return IrisFileIconClass.Gif;
      case mimeType === 'image/svg+xml':
        return IrisFileIconClass.Svg;
      case type === 'audio':
        return IrisFileIconClass.Audio;
      case type === 'video':
        return IrisFileIconClass.Video;
      case type === 'image':
        return IrisFileIconClass.Image;
      case mimeType === 'text/plain':
      case mimeType === 'text/html':
      default:
        return IrisFileIconClass.Txt;
    }
  }

  private getIconTypeByExtension(fileName: string): IrisFileIconClass {
    if (!fileName) {
      return IrisFileIconClass.Txt;
    }

    const extension = this.getExtensionFromFileName(fileName);

    switch (true) {
      case this.WORD_EXTENSIONS.includes(extension):
        return IrisFileIconClass.Word;
      case this.EXCEL_EXTENSIONS.includes(extension):
        return IrisFileIconClass.Excel;
      case this.POWERPOINT_EXTENSIONS.includes(extension):
        return IrisFileIconClass.Powerpoint;
      case this.PNG_EXTENSIONS.includes(extension):
        return IrisFileIconClass.Png;
      case this.JPG_EXTENSIONS.includes(extension):
        return IrisFileIconClass.Jpg;
      case this.GIF_EXTENSIONS.includes(extension):
        return IrisFileIconClass.Gif;
      case this.SVG_EXTENSIONS.includes(extension):
        return IrisFileIconClass.Svg;
      case this.AUDIO_EXTENSIONS.includes(extension):
        return IrisFileIconClass.Audio;
      case this.VIDEO_EXTENSIONS.includes(extension):
        return IrisFileIconClass.Video;
      case this.PDF_EXTENSIONS.includes(extension):
        return IrisFileIconClass.Pdf;
      case this.ZIP_EXTENSIONS.includes(extension):
        return IrisFileIconClass.Zip;
      case this.MESSAGE_EXTENSIONS.includes(extension):
        return IrisFileIconClass.Message;
      default:
        return IrisFileIconClass.Txt;
    }
  }

  public getColorIcon(file: IrisDMSFileI): string {
    const iconType = this.getIconType(file);
    switch (iconType) {
      case IrisFileIconClass.File:
        return 'file-list.svg';
      case IrisFileIconClass.Audio:
        return 'audio.png';
      case IrisFileIconClass.Video:
        return 'video.png';
      case IrisFileIconClass.Image:
        return 'image.png';
      case IrisFileIconClass.Zip:
        return 'zip.png';
      case IrisFileIconClass.Word:
        return 'word.svg';
      case IrisFileIconClass.Excel:
        return 'xls.svg';
      case IrisFileIconClass.Pdf:
        return 'pdf.svg';
      case IrisFileIconClass.Jpg:
        return 'jpg.svg';
      case IrisFileIconClass.Gif:
        return 'gif.svg';
      case IrisFileIconClass.Png:
        return 'png.svg';
      case IrisFileIconClass.Powerpoint:
        return 'ppt.svg';
      case IrisFileIconClass.Message:
        return 'msg.svg';
      case IrisFileIconClass.Txt:
        return 'txt.svg';
      default:
        return 'file.png';
    }
  }

  public getIconClass(file: IrisDMSFileI | IrisDataRepoFileI, fillType: IrisFileIconFillType = IrisFileIconFillType.Lite): string {
    const iconType = this.getIconType(file);
    const iconFile = IrisFileIcon.fromType(iconType, fillType);

    return iconFile.className;
  }

  public getFileIconClass(fileName: string, fillType: IrisFileIconFillType = IrisFileIconFillType.Lite): string {
    const iconType = this.getIconTypeByExtension(fileName);

    const iconFile = IrisFileIcon.fromType(iconType, fillType);

    return iconFile.className;
  }

  public getFileContentUrl(fileId: string): string {
    return `${this.envService.get<string>('apiUrl')}${this.url()}/${fileId}/content`;
  }

  @ApiUrl('~/{fileId}/content')
  public getUploadVersionUrl(fileId: string, ownerId: number): string {
    return `${this.fullUrl({ fileId })}?ownerId=${ownerId}`;
  }

  @ApiUrl(`~/{fileId}/content`)
  getFileContent(fileId: string, download = true): Observable<Blob> {
    return this.httpClient.get(this.url({ fileId }), { responseType: 'blob', params: { download } });
  }

  blobToBase64$(blob: Blob): Observable<string> {
    const promise = new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
    return from(promise);
  }

  getFileLinkBase64String$(fileLink: string): Observable<string> {
    return this.httpClient.get(fileLink, { responseType: 'blob' }).pipe(
      switchMap(blob => this.blobToBase64$(blob)),
    );
  }

  downloadFile(fileId: string, fileName?: string): Observable<unknown> {
    return this.getFileContent(fileId).pipe(
      map(response => {
        saveAs(response, fileName);
        return null;
      }),
      catchError((err: HttpErrorResponse) => {
        const message = this.translateService.instant('error.dms.downloadFile', { fileName });
        this._alertify.error(message);
        return of(err);
      }),
    );
  }
  @ApiUrl(`~/{fileId}/version/{versionId}/content`)
  downloadFileVersion(fileId: string, versionId: string, fileName?: string): Observable<unknown> {
    return this.httpClient.get(this.url({ fileId, versionId }), { responseType: 'blob' }).pipe(
      switchMap(response => {
        if (fileName) {
          return of({ response, name: fileName });
        }
        return this.getFileById(fileId).pipe(
          take(1),
          map(file => ({ response, name: file?.name })),
        );
      }),
      map(({ response, name }) => {
        saveAs(response, name);
        return null;
      }),
    );
  }

  @ApiUrl(`/dms2/{iris@instanceId}/files/download`)
  downloadFiles(filesIds: string[]): Observable<unknown> {
    return this.httpClient.post(this.url(), filesIds, { responseType: 'blob' }).pipe(
      map(response => {
        saveAs(response);
        return null;
      }),
    );
  }

  @ApiUrl(`/dms2/{iris@instanceId}/files/{fileId}/content`)
  getFileText(fileId: string): Observable<string> {
    return this.httpClient.get(this.url({ fileId }), { responseType: 'text' });
  }

  public getFilePreviewUrl(fileId: string, clearCache = false, saveRecentFile = false): string {
    let query = `download=false`;
    if (clearCache) {
      query += `&timestamp=${(new Date()).getTime()}`;
    }
    if (saveRecentFile) {
      query += `&saveRecentFile=true`;
    }
    return `${this.getFileContentUrl(fileId)}?${query}`;
  }

  public goToDMSFile(file: IrisDMSFileI | IrisDataRepoFileI): string {
    return `/ui/ui2/dms/folders/${file.parentId}/files?${IrisDMSFileQueryParam.File}=${file.id}`;
  }

  // preview methods

  public isFileAsposePreviewable(file: IrisDataRepoFileI | IrisDMSFileI): boolean {
    const mimeType = file?.mimeType?.toLowerCase();
    return this.EXCEL_MIME_TYPES.includes(mimeType) || this.MESSAGE_MIME_TYPES.includes(mimeType)
      || this.POWERPOINT_MIME_TYPES.includes(mimeType) || this.WORD_MIME_TYPES.includes(mimeType);
  }

  public isFileDefaultPreviewable(file: Partial<IrisDataRepoFileI | IrisDMSFileI>): boolean {
    const mimeType = file?.mimeType?.toLowerCase();
    if (mimeType) {
      const mimeTypeParts = mimeType.split('/');
      const type = mimeTypeParts.shift();
      const subtype = mimeTypeParts.pop();

      return (this.DEFAULT_PREVIEW_TYPES.includes(type) || this.DEFAULT_PREVIEW_SUBTYPES.includes(subtype))
        && !this.BIM_EXTENSIONS.includes(this.getExtensionFromFileName(file.name));
    }

    return false;
  }

  public isPreviewInProgress(file: IrisDataRepoFileI | IrisDMSFileI): boolean {
    if (this.isFileDefaultPreviewable(file)) {
      return false;
    }

    return this.isFileAsposePreviewable(file) && !this.isPreviewReady(file);
  }

  public isPreviewReady(file: Partial<IrisDataRepoFileI | IrisDMSFileI>): boolean {
    if (this.isFileDefaultPreviewable(file)) {
      return true;
    }

    return !!file?.isPreviewReady || !!file?.previewId;
  }

  public isImage(file: Partial<IrisDataRepoFileI | IrisDMSFileI>): boolean {
    return this.getPreviewType(file) === IrisFilePreviewType.Image;
  }

  public getPreviewType(file: Partial<IrisDataRepoFileI | IrisDMSFileI>): IrisFilePreviewType {
    if (!file) {
      return null;
    }

    const mimeType = file.mimeType?.toLowerCase();
    const hasMimeType = !!mimeType;
    const mimeTypeParts = mimeType?.split('/');
    const extension = this.getExtensionFromFileName(file?.name);
    const isExcelExtension = !!extension && this.EXCEL_EXTENSIONS.includes(extension);
    const isPreviewReady = this.isPreviewReady(file);

    switch (true) {
      case hasMimeType && this.DEFAULT_PREVIEW_SUBTYPES.includes(mimeTypeParts?.pop()):
      case hasMimeType && this.MESSAGE_MIME_TYPES.includes(mimeType):
      case hasMimeType && this.WORD_MIME_TYPES.includes(mimeType):
      case hasMimeType && this.POWERPOINT_MIME_TYPES.includes(mimeType):
      case !hasMimeType && isPreviewReady && !isExcelExtension: // POWERPOINT_EXTENSIONS, WORD_EXTENSIONS and so on
        return IrisFilePreviewType.Pdf;

      case hasMimeType && mimeType.includes(IrisFilePreviewType.Image):
      case this.IMAGE_EXTENTIONS.includes(extension):
        return IrisFilePreviewType.Image;

      case hasMimeType && this.DEFAULT_PREVIEW_TYPES.includes(mimeTypeParts?.shift()):
      case hasMimeType && this.EXCEL_MIME_TYPES.includes(mimeType):
      case !hasMimeType && isPreviewReady && isExcelExtension:
        return IrisFilePreviewType.Document;

      default:
        return null;
    }
  }

  public isOfficeDocument(mimeType: string): boolean {
    const mimeTypeLowered = mimeType?.toLowerCase();
    if (mimeTypeLowered) {
      return this.WORD_MIME_TYPES.includes(mimeTypeLowered)
        || this.POWERPOINT_MIME_TYPES.includes(mimeTypeLowered)
        || this.EXCEL_MIME_TYPES.includes(mimeTypeLowered)
        || this.ONENOTE_MIME_TYPES.includes(mimeTypeLowered)
        || this.VISIO_MIME_TYPES.includes(mimeTypeLowered);
    }
    return false;
  }

  public isOfficeEditableDocument(mimeType: string): boolean {
    const mimeTypeLowered = mimeType?.toLowerCase();
    return this.isOfficeDocument(mimeType) &&
      !this.LEGACY_WORD_MIME_TYPES.includes(mimeTypeLowered) &&
      !this.LEGACY_POWERPOINT_MIME_TYPES.includes(mimeTypeLowered) &&
      !this.LEGACY_EXCEL_MIME_TYPES.includes(mimeTypeLowered);
  }

  public isOfficeEditableExtension(fileName: string): boolean {
    if (fileName) {
      const extension = this.getExtensionFromFileName(fileName);
      return this.OFFICE_EDIT_EXTENSIONS.includes(extension);
    }
    return false;
  }

  public isOfficeFileEditable(file: Partial<IrisDMSFileI>): boolean {
    return this.isOfficeEditableDocument(file?.mimeType) || this.isOfficeEditableExtension(file?.name);
  }

  public getExtensionFromFile(file: IrisDataRepoFileI | IrisDMSFileI): string {
    const fileName = file?.name;
    return this.getExtensionFromFileName(fileName);
  }

  public getExtensionFromFileName(fileName: string): string {
    return !!fileName && fileName.includes('.') ? fileName.split('.').pop().toLowerCase() : '';
  }

  public getFileNameWithoutExtension(fileName: string): string {
    return !!fileName && fileName.includes('.') ? fileName.split('.').slice(0, -1).join('.') : fileName;
  }

  @ApiUrl('~/{fileId}/share')
  public share(
    fileId: string,
    validFor: number,
    isPermalink: boolean,
    isSilent: boolean,
    allowSingleDownloading: boolean,
  ): Observable<IrisDmsFileLinkI> {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + validFor);
    return this.httpClient.post<IrisDmsFileLinkI>(this.url({ fileId }), {}, {
      params: {
        'end-date': endDate.toISOString(),
        'permalink': String(isPermalink),
        'silent': String(isSilent),
        'allow-single-downloading': String(allowSingleDownloading),
      },
    });
  }

  @ApiUrl('/dms2/{iris@instanceId}/links/{hash}/download')
  public getLinkUrl(fileLink: IrisDmsFileLinkI): string {
    return this.fullUrl({ hash: fileLink.hash });
  }

  public getFileSourceUrl(folderId: string, fileId: string): string {
    return `${location.origin}/ui/ui2/dms/folders/${folderId}/files?${IrisDMSFileQueryParam.File}=${fileId}`; // pageUrl from config is not relevant e.g. if we start from profile page
  }

  public getVersionContentUrl(fileId: string, versionId: string): string {
    return `${this.envService.get<string>('apiUrl')}${this.url()}/${fileId}/version/${versionId}/content`;
  }

  public getVersionPreviewUrl(fileId: string, versionId: string): string {
    return `${this.getVersionContentUrl(fileId, versionId)}`;
  }

  @ApiUrl('')
  public getFilesByIds(filesIds: string[]): Observable<IrisDMSFileI[]> {
    if (!filesIds.length) {
      return of([]);
    }
    const params = new IrisQueryParamsBuilder().urlParam('files-ids', JSON.stringify(filesIds)).toObject();
    return this.httpClient.get<IrisDMSFileI[]>(this.url(), { params });
  }

  @ApiUrl(`/dms2/{iris@instanceId}/folders/{folderId}/files?saveRecentFile=true`)
  public getUploadUrl(folderId: string): string {
    return this.fullUrl({ folderId });
  }

  @ApiUrl(`/dms2/{iris@instanceId}/folders/{folderId}/{urlPart}`)
  public getZipImportUrl(folderId: string, format: IrisFileFormat = IrisFileFormat.Zip): string {
    const urlPart = format === IrisFileFormat.Zip ? 'zip-file' : 'import';
    return this.fullUrl({ folderId, urlPart });
  }

  public importZipFile(folderId: string, file: Blob): Observable<unknown> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(this.getZipImportUrl(folderId), formData);
  }

  @ApiUrl('~/{fileId}')
  public getFileById(fileId: string): Observable<IrisDMSFileI> {
    return this.httpClient.get<IrisDMSFileI>(this.url({ fileId }));
  }

  public getVersionDownloadUrl(fileId: string, versionId: string): string {
    return `${this.getVersionContentUrl(fileId, versionId)}?download=true`;
  }

  @ApiUrl('~/{fileId}/versions/{versionId}')
  public deleteFileVersion(fileId: string, versionId: string): Observable<unknown> {
    return this.httpClient.delete(this.url({ fileId, versionId }));
  }

  @ApiUrl('~/{fileId}/attachments/{attachmentId}')
  public deleteAttachments(fileId: string, attachmentId: string): Observable<unknown> {
    return this.httpClient.delete(this.url({ fileId, attachmentId }));
  }

  public getFileLinkValidityValues(): Record<string, string | number>[] {
    return [
      { id: 1, name: '1 ' + this.translateService.instant('label.day') },
      { id: 3, name: '3 ' + this.translateService.instant('label.days') },
      { id: 7, name: '1 ' + this.translateService.instant('label.Week') },
      { id: 14, name: '2 ' + this.translateService.instant('label.Weeks') },
      { id: 30, name: '1 ' + this.translateService.instant('label.Month') },
      { id: 180, name: '6 ' + this.translateService.instant('label.Months') },
      { id: 365, name: '1 ' + this.translateService.instant('label.Year') },
      { id: -1, name: this.translateService.instant('label.dms.CustomPeriodDays') },
    ];
  }

  public getFileLinkLimitDownloadValues(): Record<string, string | boolean>[] {
    return [{ id: true, name: '1 ' + this.translateService.instant('label.dms.Download') },
      { id: false, name: this.translateService.instant('label.Unlimited') }];
  }

  @ApiUrl('~/{fileId}/toggle-locked')
  public toggleLocked(fileId: string): Observable<IrisDMSFileI> {
    return this.httpClient.post<IrisDMSFileI>(this.url({ fileId }), { id: fileId });
  }

  @ApiUrl('~/{fileId}/attachments/{attachmentId}/move-to/{targetFolderId}')
  public moveAttachmentToFolder(fileId: string, attachmentId: string, targetFolderId: string): Observable<IrisDMSFileI> {
    return this.httpClient.post<IrisDMSFileI>(this.url({ fileId, attachmentId, targetFolderId }), {});
  }

  @ApiUrl('~/{fileId}/copy-to/{targetFolderId}?name={newName}')
  public copyToFolderWithNewName(fileId: string, targetFolderId: string, newName: string): Observable<IrisDMSFileI> {
    return this.httpClient.post<IrisDMSFileI>(this.url({ fileId, targetFolderId, newName }), {});
  }

  @ApiUrl('~/{fileId}/restore')
  public restoreFile(fileId: string): Observable<IrisDMSFileI> {
    return this.httpClient.post<IrisDMSFileI>(this.url({ fileId }), {});
  }

  @ApiUrl('~/{fileId}?saveRecentFile=true')
  public updateFile(file: IrisDMSFileI): Observable<IrisDMSFileI> {
    return this.httpClient.post<IrisDMSFileI>(this.url({ fileId: file.id }), file);
  }

  @ApiUrl('~/{fileId}/tags')
  public updateFileTags(fileId: string, tags: IrisDMSTagI[]): Observable<IrisDMSFileI> {
    return this.httpClient.put<IrisDMSFileI>(this.url({ fileId }), tags);
  }

  @ApiUrl('~/{fileId}/image-metadata')
  public updateFileCoordinates(fileId: string, coordinates: LatLngLiteral): Observable<IrisDMSFileI> {
    return this.httpClient.put<IrisDMSFileI>(this.url({ fileId }), { gpsLatitude: coordinates?.lat, gpsLongitude: coordinates?.lng });
  }

  @ApiUrl('~/{fileId}/offset')
  public getFileOffset(fileId: string, orderBy?: IrisQueryParamsOrderBy[]): Observable<number> {
    const extendedParams = new IrisQueryParamsBuilder();
    if (orderBy?.length) {
      extendedParams.urlParam('order-by', orderBy);
    }

    return this.httpClient.get<number>(this.url({ fileId }), { params: extendedParams.toObject() });
  }

  @ApiUrl('~/translate/{language}')
  public requestTranslation(language: string, filesIds: string[]): Observable<unknown> {
    return this.httpClient.post(this.url({ language }), filesIds);
  }

  @ApiUrl('/dms2/{iris@instanceId}/files/{fileId}/links')
  public getLinkedFiles(fileId: string): Observable<IrisDMSFileI[]> {
    return this.httpClient.get<IrisDMSFileI[]>(this.url({ fileId }));
  }

  generateFileInfoLink(file: IrisDMSFileI | IrisDataRepoFileI): string {
    const { Action } = IrisDMSFileQueryParam;
    const { OpenInfo } = IrisDMSFileQueryParamAction;
    return `${this.goToDMSFile(file)}&${Action}=${OpenInfo}`;
  }

  generateSelectFileLink(file: IrisDMSFileI): string {
    const { Action } = IrisDMSFileQueryParam;
    const { Select } = IrisDMSFileQueryParamAction;
    return `${this.goToDMSFile(file)}&${Action}=${Select}`;
  }

  @ApiUrl('~/{fileId}/links')
  public linkFiles(fileId: string, filesToLink: string[]): Observable<IrisDMSFileI[]> {
    return this.httpClient.post<IrisDMSFileI[]>(this.url({ fileId }), filesToLink);
  }

  @ApiUrl('~/{fileId}/links/{fileToUnlink}')
  public unlinkFile(fileId: string, fileToUnlink: string): Observable<IrisDMSFileI[]> {
    return this.httpClient.delete<IrisDMSFileI[]>(this.url({ fileId, fileToUnlink }));
  }

  @ApiUrl('~/{fileId}/history/link/add')
  public addLinksToFileHistory(fileId: string, linkedFiles: IrisDMSFileI[]): Observable<IrisDMSFileI> {
    const body = {};
    linkedFiles.forEach(file => {
      body[file.id] = file.name;
    });
    return this.httpClient.post<IrisDMSFileI>(this.url({ fileId }), body);
  }

  @ApiUrl(`~/{fileId}/history/link/delete`)
  public removeLinksFromFileHistory(fileId: string, linkedFiles: IrisDMSFileI[]): Observable<IrisDMSFileI> {
    const body = {};
    linkedFiles.forEach(file => {
      body[file.id] = file.name;
    });
    return this.httpClient.post<IrisDMSFileI>(this.url({ fileId }), body);
  }

  getFileInternalLink(fileId: string, openPreview = true): string {
    const url = `${location.origin}/ui/ui2/dms/file/${fileId}`;
    if (!openPreview) {
      return `${url}?${IrisDMSFileQueryParam.Action}=${IrisDMSFileQueryParamAction.OpenInfo}`;
    }
    return url; // pageUrl from config is not relevant e.g. if we start from profile page
  }

  checkLock(fileId: string): Observable<{ locked: boolean }> {
    return this.httpClient.get<{ locked: boolean }>(`/office365-editor/${this.envService.env.instanceId}/wopi/files/${fileId}/checkLock`);
  }

  checkSameFileType(oldName: string, newName: string): Observable<void> {
    return this.httpClient.post<void>(
      `/${IrisModules.DMS2}/${this.envService.env.instanceId}/files/types?oldName=${oldName}&newName=${newName}`, null);
  }

  @ApiUrl(`/dms2/{iris@instanceId}/folders/{folderId}/files/version`)
  public getFileVersionNumber(folderId: string, fileName: string): Observable<number> {
    return this.httpClient.get<number>(this.url({ folderId }), { params: { fileName } });
  }

  fileNamesExist(fileNames: string[], folderId: string): Observable<boolean> {
    return forkJoin(fileNames.map(name => this.getFileVersionNumber(folderId, name)))
      .pipe(
        map((versions) => {
          return versions.some(version => version > 0);
        }),
      );
  }

  public isZipFile(fileName: string): boolean {
    return this.ZIP_EXTENSIONS.includes(this.getExtensionFromFileName(fileName));
  }

  public isEmailFile(fileName: string): boolean {
    return this.MESSAGE_EXTENSIONS.includes(this.getExtensionFromFileName(fileName));
  }

  @ApiUrl(`/dms2/{iris@instanceId}/files/exist`)
  public fileExist(folderId: string, name: string): Observable<boolean> {
    return this.httpClient.get<boolean>(this.url(), { params: { folderId, name } });
  }

  @ApiUrl(`/dms2/{iris@instanceId}/folders/{folderId}/files/name`)
  public getFileByName(folderId: string, fileName: string): Observable<IrisDMSFileI> {
    return this.httpClient.get<IrisDMSFileI>(this.url({ folderId }), { params: { fileName } });
  }

  @ApiUrl(`/dms2/{iris@instanceId}/files/{fileId}/forms`)
  public isFileAttachedToForm(fileId: string): Observable<boolean> {
    return this.httpClient.get<boolean>(this.url({ fileId }));
  }

  @ApiUrl(`~/upload/{uploadId}/cancel`)
  public cancelUpload(uploadId: string, fileName: string): Observable<void> {
    return this.httpClient.post<void>(this.url({ uploadId }), {}, { params: { fileName } });
  }

  @ApiUrl(`~/upload/{uploadId}`)
  public saveFilesInfo(uploadId: string, filesInfo: FileUploadInfo[]): Observable<void> {
    const body = {};
    filesInfo.forEach(info => {
      body[info.fileName] = { tags: info.tags, contentLanguage: info.contentLanguage };
    });

    return this.httpClient.post<void>(this.url({ uploadId }), body);
  }

  @ApiUrl(`/dms2/{iris@instanceId}/recent/project/{projectId}`)
  public getProjectRecentFiles(projectId: number): Observable<IrisDMSFileI[]> {
    return this.httpClient.get<IrisDMSFileI[]>(this.url({ projectId }));
  }

  @ApiUrl(`/dms2/{iris@instanceId}/recent/remove-from-recent`)
  public removeRecentFiles(recentFileIds: string[]): Observable<unknown> {
    return this.httpClient.post<unknown>(this.url(), { recentFileIds });
  }

  @ApiUrl('/office365-editor/{iris@instanceId}/wopi/files/{fileId}')
  public getWopiUrl(fileId: string, isAdmin = false): string {
    if (!fileId) {
      return '';
    }
    const url = this.fullUrl({ fileId });
    const isLicensedParam = !isAdmin ? '&IsLicensedUser=1' : '';
    return `${url}${isLicensedParam}`;
  }
}
