import { Injectable } from '@angular/core';
import { ApmService } from '@elastic/apm-rum-angular';
import { wait } from '@iris/common/utils/wait';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(
    private readonly apmService: ApmService,
  ) { }

  async logMessageToKibana(message: string, transactionName = 'Custom message'): Promise<void> {
    const transaction = this.apmService.apm.startTransaction(transactionName);
    transaction.addLabels({ message });

    // wait for the transaction to be sent, otherwise the transaction will not be sent to kibana
    await wait(100);
    transaction.end();

    // for the case when it needs to perform a logout immediately after sending a transaction,
    // otherwise the transaction will not be sent to kibana
    await wait(500);
  }
}
