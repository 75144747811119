import { IrisRange } from '@iris/modules/module-filters-v2/models';
import { IrisTimeSandbox, IrisUserTimePipe } from '@iris/common/modules/time';

export function stringifyDate(date: Date, timeSandbox: IrisTimeSandbox, timezone?: string): string {
  return new IrisUserTimePipe(timeSandbox).transform(date, 'date', timezone);
}

export function dateRangeStringifyFn(dateStringifyFn: (date: Date) => string): (range: IrisRange<Date>) => string {
  return range => {
    const fromStr = dateStringifyFn(range?.from);
    const toStr = dateStringifyFn(range?.to);

    if (fromStr == toStr) {
      return fromStr;
    } else if (!fromStr) {
      return `< ${toStr}`;
    } else if (!toStr) {
      return `> ${fromStr}`;
    } else {
      return `${fromStr} - ${toStr}`;
    }
  };
}
