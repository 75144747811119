import { isEmpty } from 'lodash';
import isNil from 'lodash/isNil';
import { IrisLocaleService } from '@iris/common/modules/locale/services/locale.service';
import { IrisFieldsTranslationSet } from '@iris/common/models';

// TODO To be removed after migration to new IrisFieldsTranslationSet format
export function transformTranslate(value: unknown, translations, defaultValue?, currLocale?, localeService?: IrisLocaleService): unknown {
  let translatedValue;
  let locale = 'en';

  if (!isNil(currLocale)) {
    locale = currLocale;
  } else if (!isEmpty(localeService)) {
    const currentLocaleFromService = localeService.getCurrentLocale();
    if (!isNil(currentLocaleFromService)) {
      locale = currentLocaleFromService;
    }
  }

  if (locale && translations?.[locale]) {
    translatedValue = translations[locale];
  }

  return translatedValue || defaultValue || value;
}

export function transformTranslation(translationKey: string, translations: IrisFieldsTranslationSet, defaultValue: string, currLocale?: string, localeService?: IrisLocaleService): string {
  let translatedValue: string;
  let locale = 'en';

  if (!isNil(currLocale)) {
    locale = currLocale;
  } else if (!isEmpty(localeService)) {
    const currentLocaleFromService = localeService.getCurrentLocale();
    if (!isNil(currentLocaleFromService)) {
      locale = currentLocaleFromService;
    }
  }

  if (locale && translations?.[locale]) {
    translatedValue = translations[locale]?.[translationKey];
  }

  return translatedValue || defaultValue;
}
