/**
 * Took inspiration from https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/core/outlet/string_template_outlet.directive.ts
 */
import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[irisStringTemplateOutlet]',
  exportAs: 'irisStringTemplateOutlet',
  standalone: true,
})
export class IrisStringTemplateOutletDirective<T = unknown> implements OnChanges {
  private embeddedViewRef: EmbeddedViewRef<unknown> | null = null;
  private readonly context = new NzStringTemplateOutletContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() irisStringTemplateOutletContext: any = null;

  @Input() irisStringTemplateOutlet?: TemplateRef<T> | string | null = null;

  private recreateView(): void {
    this.viewContainer.clear();
    const isTemplateRef = this.irisStringTemplateOutlet instanceof TemplateRef;
    const templateRef = isTemplateRef ? this.irisStringTemplateOutlet as TemplateRef<T> : this.templateRef;
    this.embeddedViewRef = this.viewContainer.createEmbeddedView(
      templateRef,
      isTemplateRef ? this.irisStringTemplateOutletContext : this.context,
    );
  }

  private updateContext(): void {
    const isTemplateRef = this.irisStringTemplateOutlet instanceof TemplateRef;
    const newCtx = isTemplateRef ? this.irisStringTemplateOutletContext : this.context;
    const oldCtx = this.embeddedViewRef?.context;
    if (newCtx) {
      for (const propName of Object.keys(newCtx)) {
        oldCtx[propName] = newCtx[propName];
      }
    }
  }

  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly templateRef: TemplateRef<unknown>,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { irisStringTemplateOutletContext, irisStringTemplateOutlet } = changes;
    const shouldRecreateView = (): boolean => {
      let shouldOutletRecreate = false;
      if (irisStringTemplateOutlet) {
        if (irisStringTemplateOutlet.firstChange) {
          shouldOutletRecreate = true;
        } else {
          const isPreviousOutletTemplate = irisStringTemplateOutlet.previousValue instanceof TemplateRef;
          const isCurrentOutletTemplate = irisStringTemplateOutlet.currentValue instanceof TemplateRef;
          shouldOutletRecreate = isPreviousOutletTemplate || isCurrentOutletTemplate;
        }
      }
      const hasContextShapeChanged = (ctxChange: SimpleChange): boolean => {
        const prevCtxKeys = Object.keys(ctxChange.previousValue || {});
        const currCtxKeys = Object.keys(ctxChange.currentValue || {});
        if (prevCtxKeys.length === currCtxKeys.length) {
          for (const propName of currCtxKeys) {
            if (!prevCtxKeys.includes(propName)) {
              return true;
            }
          }
          return false;
        } else {
          return true;
        }
      };
      const shouldContextRecreate =
        irisStringTemplateOutletContext && hasContextShapeChanged(irisStringTemplateOutletContext);
      return shouldContextRecreate || shouldOutletRecreate;
    };

    if (irisStringTemplateOutlet) {
      this.context.$implicit = irisStringTemplateOutlet.currentValue;
    }

    const recreateView = shouldRecreateView();
    if (recreateView) {
      /** recreate view when context shape or outlet change **/
      this.recreateView();
    } else {
      /** update context **/
      this.updateContext();
    }
  }
}

export class NzStringTemplateOutletContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $implicit: any;
}
