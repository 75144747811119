export interface IrisSharedMailboxI extends ServerBaseEntityI {
  projectId: number;
  mail: string;
  description: string;
  language: string;
}

export type IrisEmailIntegrationLogStatusType = 'QUEUED' | 'WAIT' | 'ERROR' | 'DONE' | 'NO_RESPONSE';
export type IrisEmailIntegrationLogUserActionType = 'ADD' | 'DELETE';
export type IrisSharedMailboxCreationStatusType = 'NONE' | 'EXIST' | 'CREATING';

export const IRIS_ERROR_EMAIL_INTEGRATION_STATUS: IrisEmailIntegrationLogStatusType[] = ['ERROR', 'NO_RESPONSE'];
export const IRIS_WARNING_EMAIL_INTEGRATION_STATUS: IrisEmailIntegrationLogStatusType[] = ['QUEUED', 'WAIT'];

export enum IrisSharedMailboxUserOperation {
  Add = 'ADD',
  Delete = 'DELETE',
}

export interface IrisEmailIntegrationLogStatusI {
  status: IrisEmailIntegrationLogStatusType;
  updatedOn: Date;
}

export interface IrisEmailIntegrationLogUserI {
  userEmail: string;
  action: IrisEmailIntegrationLogUserActionType;
  status: IrisEmailIntegrationLogStatusType;
  statuses: IrisEmailIntegrationLogStatusI[];
}
export interface IrisEmailIntegrationLogI {
  projectId: number;
  mailbox: string;
  status: IrisEmailIntegrationLogStatusType;
  updatedOn: Date;
  statuses: IrisEmailIntegrationLogStatusI[];
  users: IrisEmailIntegrationLogUserI[];
}
