import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import {
  LoadEnabledProjectResourceRoleComplete,
  LoadAllProjectResourceRoleComplete,
  LoadEnabledProjectResourceRoles,
  LoadAllProjectResourceRoles,
  LoadEnabledResourceRoles,
  LoadEnabledResourceRolesComplete,
} from '@iris/common/redux/actions/resource-roles.actions';
import { IrisResourceRolesService } from '@iris/common/services/resource-roles.service';
import { IrisResourceRoleI } from '@iris/common/models/IrisResourceRole';
import { IrisQueryParamsBuilder } from '@iris/api-query';

@Injectable()
export class IrisResourceRolesEffects {
  loadEnabledResourceRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadEnabledResourceRoles),
      switchMap(action => {
        return this.resourceRolesService.getEnabled(action.resourceType).pipe(
          map((resourceRoles: IrisResourceRoleI[]) => LoadEnabledResourceRolesComplete({
            resourceRoles,
            resourceType: action.resourceType,
          })),
          catchError(() => EMPTY),
        );
      }),
      catchError(() => EMPTY),
    ),
  );

  LoadEnabledProjectResourceRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadEnabledProjectResourceRoles),
      switchMap(action => {
        return this.getProjectResourceRoles(action.projectId, true).pipe(
          map((projectRoles: IrisResourceRoleI[]) => LoadEnabledProjectResourceRoleComplete({
            projectRoles,
            projectId: action.projectId,
          })),
          catchError(() => EMPTY),
        );
      }),
    ),
  );

  LoadAllProjectResourceRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadAllProjectResourceRoles),
      switchMap(action => {
        return this.getProjectResourceRoles(action.projectId, false).pipe(
          map((projectRoles: IrisResourceRoleI[]) => LoadAllProjectResourceRoleComplete({
            projectRoles,
            projectId: action.projectId,
          })),
          catchError(() => EMPTY),
        );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly resourceRolesService: IrisResourceRolesService,
  ) {}

  getProjectResourceRoles(projectId: number, onlyEnabled: boolean): Observable<IrisResourceRoleI[]> {
    const paramsBuilder = new IrisQueryParamsBuilder()
      .urlParam('allowedPattern', `Project/${projectId}`)
      .filter('resourceType', ['Project']);

    if (onlyEnabled) {
      paramsBuilder.filter('isEnabled', [true]);
    }

    return this.resourceRolesService.query(paramsBuilder.toStructure());
  }
}
