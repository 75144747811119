import { IrisMailI } from '@iris/common/models/IrisMail';

export interface IrisEmailAddressI {
  address: string;
  name?: string;
}

export interface IrisEmailRecipientI {
  emailAddress: IrisEmailAddressI;
}

export interface IrisEmailDateTimeI {
  dateTime: string;
  timeZone: string;
}

export interface IrisEmailFlagI {
  completedDateTime: IrisEmailDateTimeI;
  dueDateTime: IrisEmailDateTimeI;
  startDateTime: IrisEmailDateTimeI;
  flagStatus: string;
}

export const inlineAttachmentsRegExp = new RegExp(/cid:[^"]*/g);
export interface IrisEmailDraftMessageI {
  id?: string;
  to?: (number & string)[];
  cc?: (number & string)[];
  bcc?: (number & string)[];
  subject?: string;
  body?: string;
  attachments?: number[];
  relatedMessages?: IrisEmailMessageI[];
}

export interface IrisEmailMessageResponseI {
  id: string;
  categories?: string[];
  subject: string;
  body: {
    content: string;
    contentType: string;
  };
  bodyPreview: string;
  toRecipients?: IrisEmailRecipientI[];
  bccRecipients?: IrisEmailRecipientI[];
  ccRecipients?: IrisEmailRecipientI[];
  conversationId?: string;
  conversationIndex?: string[];
  flag?: IrisEmailFlagI;
  from: IrisEmailRecipientI;
  hasAttachments: boolean;
  importance?: string;
  isDraft: boolean;
  isRead: boolean;
  parentFolderId: string;
  receivedDateTime: string;
  replyTo?: IrisEmailRecipientI[];
  sender: IrisEmailRecipientI;
  sentDateTime?: string;
  webLink?: string;
  attachments: {
    currentPage: IrisEmailMessageAttachmentI[];
  };
}

export interface IrisEmailMessageI {
  id: string;
  subject?: string;
  body: {
    content: string;
    contentType?: string;
  };
  bodyPreview?: string;
  to?: IrisEmailRecipientI[];
  cc?: IrisEmailRecipientI[];
  bcc?: IrisEmailRecipientI[];
  conversationId?: string;
  conversationIndex?: string[];
  hasAttachments: boolean;
  attachments?: IrisEmailMessageAttachmentI[];
  relatedMessages?: IrisEmailMessageI[];
  createdOn?: string;
  createdBy?: number;
  updatedOn?: string;
  from?: IrisEmailRecipientI;
  markedAsRead?: boolean;
  isDraft?: boolean;
  parentFolderId: string;

  normalizedBody(): string;
  get shouldRequestInlineAttachments(): boolean;
  get shouldRequestAttachments(): boolean;
  get inlineAttachments(): IrisEmailMessageAttachmentI[];
  get notInlineAttachments(): IrisEmailMessageAttachmentI[];

  hasInlineAttachments(body: string): boolean;
  fromResponse(response: IrisEmailMessageResponseI): IrisEmailMessage;

  readonly INLINE_CONTENT_PREFIX: string;
}

export interface IrisEmailMessageAttachmentI {
  id?: string;
  contentBytes: string;
  contentType: string;
  contentId?: string;
  contentLocation?: string;
  isInline: boolean;
  lastModifiedDateTime: string;
  name: string;
  size: number;
  isError?: boolean;
}

export interface IrisEmailMessageAttachmentsResponseI {
  count: number;
  currentPage: IrisEmailMessageAttachmentI[];
  nextPage: string;
}

export class IrisEmailMessage implements IrisEmailMessageI {
  id: string = undefined;
  subject?: string = undefined;
  body: {
    content: string;
    contentType?: string;
  } = { content: undefined, contentType: undefined };
  bodyPreview?: string = undefined;
  to?: IrisEmailRecipientI[] = [];
  cc?: IrisEmailRecipientI[] = [];
  bcc?: IrisEmailRecipientI[] = [];
  conversationId?: string = undefined;
  conversationIndex?: string[] = [];
  hasAttachments = false;
  attachments?: IrisEmailMessageAttachmentI[] = [];
  relatedMessages?: IrisEmailMessageI[] = [];
  createdOn?: string = undefined;
  createdBy?: number = undefined;
  updatedOn?: string = undefined;
  from?: IrisEmailRecipientI = undefined;
  markedAsRead?: boolean = undefined;
  isDraft?: boolean = undefined;
  parentFolderId: string = undefined;

  readonly INLINE_CONTENT_PREFIX: string = 'cid:';

  constructor(params?: Partial<IrisEmailMessageI>) {
    const newParams = { ...params };

    if (params) {
      Object.keys(this).forEach((key) => {
        if (newParams[key] !== undefined) {
          this[key] = newParams[key];
        }
      });
    }
    this.relatedMessages = this.relatedMessages.map(message => new IrisEmailMessage(message));
  }

  normalizedBody(): string {
    let body = this.body?.content;
    if (!body) { return ''; }
    if (!this.hasInlineAttachments(body)) { return body; }
    if (this.inlineAttachments.length) {
      this.inlineAttachments.forEach(inlineAttachment => {
        const image = `data:${inlineAttachment.contentType};base64,${inlineAttachment.contentBytes}`;
        body = body.replace(`${this.INLINE_CONTENT_PREFIX}${inlineAttachment.contentId}`, image);
      });
    } else {
      body = body.replace(inlineAttachmentsRegExp, '');
    }
    return body;
  }

  get shouldRequestInlineAttachments(): boolean {
    return this.hasInlineAttachments(this.body?.content) && !this.inlineAttachments.length;
  }

  get shouldRequestAttachments(): boolean {
    return this.hasAttachments && !this.notInlineAttachments.length;
  }
  get inlineAttachments(): IrisEmailMessageAttachmentI[] {
    return this.attachments?.filter(attachment => attachment.isInline);
  }

  get notInlineAttachments(): IrisEmailMessageAttachmentI[] {
    return this.attachments?.filter(attachment => !attachment.isInline);
  }

  hasInlineAttachments(body: string): boolean {
    return !!body.replace(/(\r\n|\n|\r)/gm, '').match(inlineAttachmentsRegExp)?.length;
  }

  fromResponse(response: IrisEmailMessageResponseI): IrisEmailMessage {
    this.id = response.id;
    this.subject = response.subject;
    this.body = response.body;
    this.bodyPreview = response.bodyPreview;
    this.to = response.toRecipients;
    this.cc = response.ccRecipients;
    this.bcc = response.bccRecipients;
    this.conversationId = response.conversationId;
    this.conversationIndex = response.conversationIndex;
    this.createdOn = response.sentDateTime;
    this.updatedOn = response.receivedDateTime;
    this.from = response.from;
    this.markedAsRead = response.isRead;
    this.isDraft = response.isDraft;
    this.parentFolderId = response.parentFolderId;
    this.hasAttachments = response.hasAttachments;
    this.attachments = response.attachments?.currentPage ?? [];
    return this;
  }

  static fromDmsEmail(email: IrisMailI): Partial<IrisEmailMessageResponseI> {
    return {
      subject: email.subject,
      body: {
        content: email.message,
        contentType: 'HTML',
      },
      toRecipients: email.to.map(address => ({ emailAddress: { address } })),
      ccRecipients: email.cc.map(address => ({ emailAddress: { address } })),
      from: ({ emailAddress: { address: email.from } }),
    };
  }
}

export interface IrisEmailContactAddressI {
  city: string;
  countryOrRegion: string;
  postalCode: string;
  state: string;
  street: string;
}
export interface IrisEmailsContactI {
  id: string;
  createdDateTime: Date;
  lastModifiedDateTime: Date;
  birthday: Date;
  emailAddresses: IrisEmailAddressI[];
  companyName: string;
  department: string;
  displayName: string;
  fileAs: string;
  generation: string;
  givenName: string;
  homeAddress: IrisEmailContactAddressI;
  homePhones: string[];
  initials: string;
  jobTitle: string;
  manager: string;
  middleName: string;
  mobilePhone: string;
  nickName: string;
  parentFolderId: string;
  personalNotes: string;
  profession: string;
  spouseName: string;
  surname: string;
  title: string;
}

export enum EmailMesssageChangeActionType {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Deleted = 'DELETED',
}
export interface EmailMessageChangeEventI {
  instanceId: string;
  userId: string;
  changeType: EmailMesssageChangeActionType;
  id: string;
  parentFolderId: string;
}
