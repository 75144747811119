import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize',
})
export class IrisFileSizePipe implements PipeTransform {

  units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];

  transform(value: string | number, precision?: number): string {
    const bytes = typeof value === 'string' ? parseFloat(value) : Number(value);
    if (isNaN(bytes) || !isFinite(bytes)) { return '-'; }
    if (bytes === 0) { return '0 bytes'; }
    if (typeof precision === 'undefined') { precision = 1; }
    const number = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + this.units[number];
  }
}
