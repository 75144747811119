import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { TranslatePipe } from '@ngx-translate/core';
import { AnnouncementNewFunctionalityConfig } from '@iris/modules/administration/administration-list/announcement-page/models/announcement-models';
import { IrisTranslatePipe } from '@iris/common/modules/pipes-common/pipes';

const RELEASE_NOTES_URL= 'https://confluence.strabag.io/iris-help-center/pmh/iris-pmh-release-notes-249852734.html';

@Component({
  selector: 'iris-new-functionality-button',
  templateUrl: './iris-new-functionality-button.component.html',
  styleUrls: ['./iris-new-functionality-button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgxTippyModule, TranslatePipe, IrisTranslatePipe],
})
export class IrisNewFunctionalityButtonComponent {
  @Input() config: AnnouncementNewFunctionalityConfig;

  goToReleaseNotes(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    window.open(RELEASE_NOTES_URL, '_blank');
  }
}
