import { DateAdapter } from '@angular/material/core';
import {
  DateObjectUnits,
  DateTime,
  DateTimeOptions,
  Duration,
  DurationLike,
  DurationObjectUnits,
  ToISOTimeDurationOptions,
  Info,
  ToISOTimeOptions,
} from 'luxon';
import { normalizeUnit, parseLuxon, parseLuxonDuration } from '@iris/common/utils/date.utils';

export type DatetimeDurationUnit = 'year' |'years' |'quarter' |'quarters' |'month'
  |'months' |'week' |'weeks' |'day' |'days' |'hour' |'hours' |'minute' |'minutes'
  |'second' |'seconds' |'millisecond' |'milliseconds';

export type DatetimeUnit = 'year' | 'quarter' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second' | 'millisecond';
export type DatetimeUnitOptional = Partial<Record<DatetimeUnit, number>>;

export type AbstractToISOTimeDurationOptions = ToISOTimeDurationOptions;

export type AbstractDurationObject = DurationObjectUnits;

export class AbstractDuration {
  static parse(...args: Parameters<typeof parseLuxonDuration>): AbstractDuration {
    return new AbstractDuration(parseLuxonDuration(...args));
  }

  static isAbstractDuration(o): boolean {
    return o?.isAbstractDuration || false;
  }

  get nativeDuration(): Duration {
    return this.duration;
  }

  readonly isAbstractDuration = true;

  private constructor(
    private readonly duration: Duration,
  ) {  }

  toObject(): AbstractDurationObject {
    return this.nativeDuration.toObject();
  }

  toString(opts?: AbstractToISOTimeDurationOptions): string {
    return this.duration.toISOTime(opts);
  }

  toFormat(format: string): string{
    return this.duration.toFormat(format);
  }

  toHuman(opts?: unknown): string {
    return this.duration.toHuman(opts);
  }

  as(format: DatetimeDurationUnit): number{
    return this.duration.as(format);
  }

  shiftTo(...args) {
    return this.duration.shiftTo(...args);
  }

  shiftToAll() {
    return this.duration.shiftToAll();
  }

  get years(): number {
    return this.duration?.years || NaN;
  }
  get quarters(): number {
    return this.duration?.quarters || NaN;
  }
  get months(): number {
    return this.duration?.months || NaN;
  }
  get weeks(): number {
    return this.duration?.weeks || NaN;
  }
  get days(): number {
    return this.duration?.days || NaN;
  }
  get hours(): number {
    return this.duration?.hours || NaN;
  }
  get minutes(): number {
    return this.duration?.minutes || NaN;
  }
  get seconds(): number {
    return this.duration?.seconds || NaN;
  }
  get milliseconds(): number {
    return this.duration?.milliseconds || NaN;
  }
}

export class AbstractDatetime {
  static readonly dateAdapter: DateAdapter<DateTime>;

  static parse(...args: Parameters<typeof parseLuxon>): AbstractDatetime {
    return new AbstractDatetime(parseLuxon(...args));
  }

  static createDate(year: number, month: number, day: number): AbstractDatetime {
    return AbstractDatetime.parse({ year, month, day });
  }

  static fromFormat(datestring: string, format: string, options?: DateTimeOptions): AbstractDatetime {
    return new AbstractDatetime(DateTime.fromFormat(datestring, format, options));
  }

  static fromISO(datestring: string): AbstractDatetime {
    return new AbstractDatetime(DateTime.fromISO(datestring));
  }

  static nowLocal(): AbstractDatetime {
    return new AbstractDatetime(DateTime.now().setZone('local'));
  }

  static nowUtc(): AbstractDatetime {
    return new AbstractDatetime(DateTime.now().setZone('utc'));
  }

  static nowInTimezone(timezone: string): AbstractDatetime {
    return new AbstractDatetime(DateTime.now().setZone(timezone));
  }

  static guessTimeZone(): string {
    return DateTime.local().zoneName;
  }

  static clone(date: AbstractDatetime): AbstractDatetime {
    return new AbstractDatetime(date.datetime);
  }

  static addCalendarYears(date: AbstractDatetime, years: number): AbstractDatetime {
    return new AbstractDatetime(AbstractDatetime.dateAdapter.addCalendarYears(date.nativeDateTime, years));
  }

  static getYear(date: AbstractDatetime): number {
    return AbstractDatetime.dateAdapter.getYear(date.nativeDateTime);
  }

  static addCalendarMonths(date, months): AbstractDatetime {
    return date.addCalendarMonths(months);
  }

  /*
    static addCalendarDays(date, days): AbstractDatetime {

    }
    static getYear(date): AbstractDatetime {

    }
    static getMonth(date): AbstractDatetime {

    }
    static getDate(date): AbstractDatetime {

    }
    static getMonthNames(style): AbstractDatetime {

    }
  static getDateNames() {

  }
  static getDayOfWeekNames(style) {

  }
  static getYearName(date) {

  }
  static getFirstDayOfWeek() {

  }
  static getNumDaysInMonth(date) {

  }
  static createDate(year, month, date) {

  }
  */
  static today(): AbstractDatetime {
    return new AbstractDatetime(AbstractDatetime.dateAdapter.today());
  }
  static format(date: AbstractDatetime | null, displayFormat): string {
    if (date){
      return date.toString(displayFormat);
    }
    return '';
  }
  static toIso8601(date: AbstractDatetime): string {
    return date.datetime.toISO();
  }
  static isDateInstance(obj, type, zone: string): boolean {
    console.error('Dont implemented');
    return true;
  }
  static isValid(...args: Parameters<typeof parseLuxon>): boolean {
    return AbstractDatetime.parse(...args).isValid();
  }
  static isAbstractDate(date: unknown): boolean {
    return date instanceof AbstractDatetime;
  }
  static dateTimeForTimePicker(date: Date, timezone = 'local'): AbstractDatetime {
    return AbstractDatetime.parse(date).setTimezone(timezone);
  }

  static max(...dateTimes: (string | Date | AbstractDatetime | DatetimeUnitOptional)[]): AbstractDatetime {
    const castedDateTimes = dateTimes.map(date => date instanceof AbstractDatetime ? date.datetime : parseLuxon(date));
    return new AbstractDatetime(DateTime.max(...castedDateTimes));
  }

  static min(...dateTimes: (string | Date | AbstractDatetime | DatetimeUnitOptional)[]): AbstractDatetime {
    const castedDateTimes = dateTimes.map(date => date instanceof AbstractDatetime ? date.datetime : parseLuxon(date));
    return new AbstractDatetime(DateTime.min(...castedDateTimes));
  }

  static months(locale?: string): string[] {
    return Info.months('long', { locale });
  }

  static monthsShort(locale?: string): string[] {
    return Info.months('short', { locale });
  }

  static firstDayOfWeek(locale?: string): number {
    return DateTime.now().setLocale(locale).startOf('week').weekday;
  }

  static weekdays(locale?: string): string[] {
    return Info.weekdays('long', { locale });
  }

  static weekdaysShort(locale?: string): string[] {
    return Info.weekdays('short', { locale });
  }

  static weekdaysMin(locale?: string): string[] {
    return Info.weekdays('narrow', { locale });
  }

  static get locale(): string {
    return AbstractDatetime.nowLocal().datetime.locale;
  }

  get nativeDateTime(): DateTime {
    return this.datetime;
  }

  private constructor(
    private readonly datetime: DateTime,
  ) {}

  toString(format?: string): string {
    if (format == null) {
      return this.toIsoString();
    }

    const luxonFormat = format.replace(/Y/g, 'y').replace(/D/g, 'd');
    return this.datetime.toFormat(luxonFormat);
  }

  toJSON(): string {
    return this.toIsoString();
  }

  toIsoString(opts?: ToISOTimeOptions): string {
    return this.datetime.toISO(opts);
  }

  timezoneName(): string {
    return this.datetime.zoneName;
  }

  toJSDate(): Date {
    return this.datetime.toJSDate();
  }

  diff(other: AbstractDatetime, unit?: DatetimeDurationUnit | DatetimeDurationUnit[]): AbstractDuration {
    return AbstractDuration.parse(this.datetime.diff(other.datetime, normalizeUnit(unit)));
  }

  diffToObject(other: AbstractDatetime, unit?: DatetimeDurationUnit): AbstractDurationObject {
    return this.diff(other, unit).toObject();
  }

  /**
     * Immutable.
     */
  setLocalTimezone(keepLocalTime = false): AbstractDatetime {
    return new AbstractDatetime(this.datetime.setZone('local', { keepLocalTime }));
  }

  /**
     * Immutable.
     */
  setUtcTimezone(keepLocalTime = false): AbstractDatetime {
    return new AbstractDatetime(this.datetime.setZone('utc', { keepLocalTime }));
  }

  /**
     * Immutable.
     */
  setTimezone(timezone: string, keepLocalTime = false): AbstractDatetime {
    return new AbstractDatetime(this.datetime.setZone(timezone, { keepLocalTime }));
  }

  /**
     * Immutable.
     */
  setDate(values: DateObjectUnits): AbstractDatetime {
    return new AbstractDatetime(this.datetime.set(values));
  }

  /**
     * Immutable.
     */
  plus(duration: DurationLike): AbstractDatetime {
    return new AbstractDatetime(this.datetime.plus(duration));
  }

  /**
     * Immutable.
     */
  minus(duration: DurationLike): AbstractDatetime {
    return new AbstractDatetime(this.datetime.minus(duration));
  }

  /**
     * Immutable.
     */
  startOf(unit: DatetimeUnit): AbstractDatetime {
    return new AbstractDatetime(this.datetime.startOf(unit));
  }

  /**
     * Immutable.
     */
  endOf(unit: DatetimeUnit): AbstractDatetime {
    return new AbstractDatetime(this.datetime.endOf(unit));
  }

  equals(target: AbstractDatetime, precision: DatetimeUnit): boolean {
    return target != null && this.datetime.hasSame(target.datetime, precision);
  }

  greater(target: AbstractDatetime): boolean {
    return target == null || this.datetime > target.datetime;
  }

  less(target: AbstractDatetime): boolean {
    return target != null && this.datetime < target.datetime;
  }

  format(formatString: string): string {
    return this.toString(formatString);
  }

  getHour(): number {
    return this.datetime.hour;
  }

  getMinute(): number {
    return this.datetime.minute;
  }

  getDay(): number {
    return this.datetime.day;
  }

  get weekNumber(): number {
    return this.datetime.weekNumber;
  }

  get offset(): number {
    return this.datetime.offset;
  }

  //get date oof
  date(day: number): AbstractDatetime {
    //Gets or sets the day of the month.
    return new AbstractDatetime( this.datetime.set({ day }));
  }

  utc(): AbstractDatetime {
    return this.setUtcTimezone();
  }

  isValid(): boolean {
    return this.datetime.isValid;
  }

  isBetween(dateStart: AbstractDatetime, dateEnd: AbstractDatetime): boolean {
    return this > dateStart && this < dateEnd;
  }

  toMilliseconds(): number {
    return this.datetime.toMillis();
  }

  setDay(day: number): AbstractDatetime {
    return new AbstractDatetime(this.datetime.set({ day }));
  }

  getWeek(): number {
    return this.datetime.weekNumber;
  }

  setWeek(weekNumber: number): AbstractDatetime {
    return new AbstractDatetime(this.datetime.set({ weekNumber }));
  }

  setIsoWeek(weekYear: number): AbstractDatetime {
    return new AbstractDatetime(this.datetime.set({ weekYear }));
  }

  getMonth(): number {
    return this.datetime.month;
  }

  setMonth(month: number): AbstractDatetime {
    return new AbstractDatetime(this.datetime.set({ month }));
  }

  getYear(): number {
    return this.datetime.year;
  }

  setYear(year: number): AbstractDatetime {
    return new AbstractDatetime(this.datetime.set({ year }));
  }

  setHours(hour: number): AbstractDatetime {
    return new AbstractDatetime(this.datetime.set({ hour }));
  }

  setMinutes(minute: number): AbstractDatetime {
    return new AbstractDatetime(this.datetime.set({ minute }));
  }

  setSeconds(second: number): AbstractDatetime {
    return new AbstractDatetime(this.datetime.set({ second }));
  }

  getSeconds(): number {
    return this.datetime.second;
  }

  clone(date?: AbstractDatetime): AbstractDatetime {
    if (date) {
      return new AbstractDatetime(date.datetime);
    }
    return new AbstractDatetime(this.datetime);
  }

  getWeekday(): number {
    return this.datetime.weekday;
  }

  getTimezoneOffset(): number {
    return this.datetime.offset;
  }

  getLocale(): string {
    return this.datetime.locale;
  }

  setLocale(locale: string): AbstractDatetime {
    return new AbstractDatetime(this.datetime.setLocale(locale));
  }
}

