<div class="login iris-dark">
  <div class="bg">
    <img src="assets/map.svg" />
  </div>
  <div class="login-content">
    <language-select class="position-absolute top-0 end-0 mt-2 me-2"></language-select>

    <div class="login-logo">
      @if (imageUrl$ | async) {
        <img [src]="imageUrl$ | async | safeResourceUrl" alt="Logo">
      }
    </div>
    <router-outlet></router-outlet>
  </div>
  @if (isAgreementVisible) {
    <div class="cookie-agreement-box">
      <div class="alert mb-0 bg-grey-border">
        <i class="fal fa-2x fa-fw fa-cookie"></i>
        <p class="mb-0 flex-grow-1">{{ 'message.CookieAgreementPopover' | translate }}</p>
        <button
          data-test="accept-cookies-button"
          class="btn btn-flat bg-background"
          type="button"
          (click)="acceptCookies()">{{ 'label.df.Understood' | translate }}</button>
      </div>
    </div>
  }
  <div class="footer py-2 text-center text-rich text-secondary-light">
    <button
      data-test="show-imprint-modal"
      class="btn btn-link"
      type="button"
      (click)="showInfoModal()">{{'label.Imprint' | translate}}</button>
  </div>
</div>
