import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  forwardRef, Host,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { merge } from 'lodash';
import { IrisFieldBaseComponent } from '../field-base/field-base.component';
import { EnhancedControlDirective } from '@iris/common/modules/fields/base-control/enhanced-control.component';

@Component({
  selector: 'iris-field-password',
  templateUrl: './field-password.component.html',
  styleUrls: ['./field-password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IrisFieldPasswordComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [{
    directive: EnhancedControlDirective,
    inputs: ['stylingMode'],
  }],
})
export class IrisFieldPasswordComponent extends IrisFieldBaseComponent implements ControlValueAccessor {

  @Input() required = false;

  // this attr allows to change browser autocomplete
  @Input() autocomplete: string;

  showPassword = false;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Optional() @Inject(NG_VALIDATORS) validators: any[],
    @Optional() readonly formControl: FormControl,
    readonly changeDetector: ChangeDetectorRef,
    @Host() public readonly enhancedControl: EnhancedControlDirective,
  ) {
    super(merge(validators || [], formControl?.validator ? [formControl.validator] : []), formControl, changeDetector);
  }
}
