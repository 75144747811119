import { NgModule } from '@angular/core';
import { IrisUserAvatarComponent } from '@iris/common/modules/user-common/modules/user-avatar/components/user-avatar.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [IrisUserAvatarComponent],
  exports: [IrisUserAvatarComponent],
})
export class IrisUserAvatarModule {
}
